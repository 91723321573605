export const timeEntryInitialState = {
  timeEntry: {
    id: '',
    description: '',
    workspace: { id: '', name: '' },
    project: { id: '', name: '' },
    stop: '',
    start: ''
  },
  validateHasTimeEntryActive: true,
  isLoading: false,
  startStopwatchWithDate: null,
  workspaces: [],
  projects: []
}

export const timeEntryReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TIME_ENTRY':
      return { ...state, timeEntry: { ...state.timeEntry, [action.name]: action.value } }
    case 'SET_ALL_TIME_ENTRY':
      return { ...state, timeEntry: { ...timeEntryInitialState.timeEntry, ...action.payload } }
    case 'SET_WORKSPACE':
      return {
        ...state,
        timeEntry: {
          ...state.timeEntry,
          workspace: action.value,
          project: timeEntryInitialState.timeEntry.project
        }
      }
    case 'SET_WORKSPACES':
      return { ...state, workspaces: action.payload }
    case 'SET_PROJECTS':
      return { ...state, projects: action.payload }
    case 'SET_DEFAULT_TIME_ENTRY':
      return { ...state, timeEntry: { ...timeEntryInitialState.timeEntry } }
    case 'IS_LOADING':
      return { ...state, isLoading: action.payload }
    case 'SET_VALIDATE_HAS_TIME_ENTRY_ACTIVE':
      return { ...state, validateHasTimeEntryActive: action.payload }
    case 'CLONE_TIME_ENTRY':
      return {
        ...state,
        startStopwatchDate: action.payload.start,
        timeEntry: { ...timeEntryInitialState.timeEntry, ...action.payload }
      }
    case 'SET_STATE':
      return { ...state, ...action.payload }
    case 'CLEAR_STOPWATCH_DATE':
      return { ...state, startStopwatchDate: null }
    default:
      return state
  }
}
