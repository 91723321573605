import React, { useContext } from 'react'
import classNames from 'classnames'

import { useHistory } from 'react-router-dom'

import authContext from 'contextApi/AuthContext'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Hidden from '@material-ui/core/Hidden'
import Popper from '@material-ui/core/Popper'
import Divider from '@material-ui/core/Divider'

// @material-ui/icons

// core components

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle'

import { routesLink } from 'constants/routesConsts'

const useStyles = makeStyles(styles)

export default function HeaderLinks(props) {
  const history = useHistory()
  const [openNotification, setOpenNotification] = React.useState(null)
  const { userLoggedin, logout } = useContext(authContext)

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null)
    } else {
      setOpenNotification(event.currentTarget)
    }
  }
  const handleCloseNotification = () => {
    setOpenNotification(null)
  }
  const [openProfile, setOpenProfile] = React.useState(null)
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }
  const handleCloseProfile = () => {
    setOpenProfile(null)
  }

  const goToProfile = () => {
    setOpenProfile(null)
    history.push(routesLink.me)
  }

  const classes = useStyles()
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover)
  const managerClasses = classNames({
    [classes.managerClasses]: true
  })
  return (
    <>
      <div className={managerClasses}>
        <div
          color="transparent"
          aria-label="Perfil"
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Hidden mdDown implementation="css">
            <img
              src={userLoggedin.picture}
              className={`${classes.photo} ${classes.links}`}
              alt="..."
            />
          </Hidden>
          <Hidden mdUp implementation="css">
            <img
              src={userLoggedin.picture}
              className={`${classes.photoMobile} ${classes.links}`}
              alt="..."
            />
          </Hidden>
        </div>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id="profile-menu-list" style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem onClick={logout} className={dropdownItem}>
                      Salir
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  )
}

HeaderLinks.propTypes = {}
