import { format } from 'date-fns'

export const contextApiReducerDefault = {
  stopwatchIsRunning: false,
  shouldGetTimeEntries: false,
  timeEntryCopy: {
    id: '',
    description: '',
    workspace: { id: '', name: '' },
    project: { id: '', name: '' },
    stop: '',
    start: ''
  },
  intervalId: null,
  startAt: '',
  secondsCounter: 0,
  startStopwatchWithDate: null,
  shouldGetActiveTime: true
}

const contextApiReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, [action.name]: action.value }
    case 'SET_ALL_STATE':
      return { ...state, ...action.payload }
    case 'SET_TIME_ENTRY_COPY':
      return {
        ...state,
        timeEntryCopy: {
          ...state.timeEntryCopy,
          ...action.payload,
          start: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        }
      }
    case 'SET_DEFAULT_TIME_ENTRY_COPY':
      return {
        ...state,
        timeEntryCopy: { ...contextApiReducerDefault.timeEntryCopy }
      }
    case 'SET_SECONDS_COUNTER_ADD_ONE':
      return { ...state, secondsCounter: Math.floor((new Date() - state.startAt) / 1000) }
    default:
      return state
  }
}

export default contextApiReducer
