import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

export default makeStyles(() => ({
  ...styles,
  tdCell: {
    fontSize: '16px',
    fontWeight: '400'
  }
}))
