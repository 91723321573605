import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  btnAction: {
    // display: 'block',
    ':hover': {
      display: 'none'
    }
  }
}))
