import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import textLabels from 'utils/MUIDataTableTextLabels'
import SweetAlert from 'react-bootstrap-sweetalert'

// Constants
import { URL_API_ROLES as urlApiModel } from 'constants/urls'

// Material ui core
import { Grid, CircularProgress, Typography } from '@material-ui/core'

// Template Components
import useCustomSnackbar from 'components/useCustomSnackbar'
import Button from 'components/CustomButtons/Button'
import ModalContainer from 'components/ModalContainer'
import CustomToolbar from 'components/CustomToolbarMuiDatatable'

// Material ui icons
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import ModelForm from './RoleForm'

import styles from './permissions.styles'

export default function Roles() {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [modelIdToEdit, setModelIdToEdit] = useState(null)
  const [opeModalModel, setOpenModalModel] = useState(false)
  const [alert, setAlert] = useState(null)
  const showNotification = useCustomSnackbar()

  const classes = styles()
  const getModels = async () => {
    setIsLoading(true)
    const result = await axios.get(urlApiModel)
    if (result) setIsLoading(false)
    if (result.status === 200) setData(result.data)
  }
  useEffect(() => {
    getModels()
  }, [])

  const successDelete = async (modelId) => {
    setAlert(null)
    const response = await axios.delete(`${urlApiModel}/${modelId}`)
    if (response.status === 204) {
      showNotification('Registro eliminado correctamente')
      getModels()
    }
  }

  const cancelDetele = () => {
    setAlert(null)
  }

  const warningWithConfirmAndCancelMessage = (modelId) => {
    setAlert(
      <SweetAlert
        warning
        title="Está seguro que desea eliminar este asesor?"
        onConfirm={() => successDelete(modelId)}
        onCancel={() => cancelDetele()}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
        cancelBtnCssClass={`${classes.button} ${classes.danger}`}
        confirmBtnText="Aceptar"
        cancelBtnText="Cancelar"
        showCancel
      />
    )
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
        print: false,
        filter: false,
        sort: false
      }
    },
    {
      name: 'name',
      label: 'Nombre'
    },
    {
      name: '',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid container>
              <Button
                onClick={() => {
                  setModelIdToEdit(tableMeta.rowData[0])
                  setOpenModalModel(true)
                }}
                justIcon
                round
                color="linkedin"
                size="sm"
                className={classes.buttonAction}
              >
                <EditIcon />
              </Button>
              <Button
                onClick={() => {
                  warningWithConfirmAndCancelMessage(tableMeta.rowData[0])
                }}
                justIcon
                round
                color="danger"
                size="sm"
                className={classes.buttonAction}
              >
                <CloseIcon />
              </Button>
            </Grid>
          )
        }
      }
    }
  ]
  const options = {
    print: false,
    filter: false,
    viewColumns: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowHover: true,
    selectableRows: 'none',
    textLabels,
    customToolbar: () => {
      return (
        <CustomToolbar
          tooltip="Agregar role"
          onClick={() => {
            setModelIdToEdit(null)
            setOpenModalModel(true)
          }}
        />
      )
    }
  }

  const handleCloseModalModel = () => {
    setOpenModalModel(false)
    getModels()
  }
  return (
    <>
      <Grid container justify="center">
        <Grid item md={6} xs={12}>
          <MUIDataTable
            title={
              <Typography variant="h6">
                Lista de roles
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </Typography>
            }
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <ModalContainer
        title={`${modelIdToEdit ? 'Actualizar' : 'Crear'} Role`}
        open={opeModalModel}
        onClose={handleCloseModalModel}
        size="sm"
      >
        <ModelForm modelId={modelIdToEdit} onCancel={handleCloseModalModel} />
      </ModalContainer>
      {alert}
    </>
  )
}
