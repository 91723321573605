import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { URL_API_TIMEENTRIES } from 'constants/urls'
import textLabels from 'utils/MUIDataTableTextLabels'
import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'
import { addTimes } from 'utils/functions'

import timeTrackerContext from 'contextApi/TimeTrackerContext'
import { loadState } from 'services/localStorage'

// Material ui core
import { Grid } from '@material-ui/core'

// Material ui icons
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

// Core componentes
import Button from 'components/CustomButtons/Button'
import TimeEntriesTable from './TimeEntriesTable'
import useStyles from './timeEntriesTable.styles'

function TimeEntriesTableContainer() {
  const classes = useStyles()
  const [timeEntries, setTimeEntries] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalTime, setTotalTime] = useState('00:00:00')

  const { stopwatchIsRunning, setTimeEntryCopy } = useContext(timeTrackerContext)
  const session = loadState()
  const sumTimes = (data) => {
    const times = data.map((item) => item.duration)
    setTotalTime(addTimes(['00:00:00', ...times]))
  }

  const getTimeEntries = async () => {
    setIsLoading(true)
    try {
      const result = await axios.get(URL_API_TIMEENTRIES, {
        headers: { Authorization: `Bearer ${session.accessToken}` }
      })
      if (result.status === 200) {
        setTimeEntries(result.data)
        if (result.data.length) {
          sumTimes(result.data)
        }
      }
      if (result) setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // TODO hacer mejora para que solo realice el consumo cuando se monte el componente o cuando stopwatchIsRunning === false
    getTimeEntries()
    return () => {}
  }, [stopwatchIsRunning])

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
        print: false,
        filter: false,
        sort: false
      }
    },
    {
      name: 'description',
      label: 'Descripción'
    },
    {
      name: 'workspaceId',
      label: 'Workspace',
      options: {
        download: false,
        print: false,
        filter: false,
        customBodyRender: (value, tableMeta) =>
          timeEntries.find((item) => item.id === tableMeta.rowData[0]).workspace.name
      }
    },
    {
      name: 'projectId',
      label: 'Proyecto',
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        empty: true,
        customBodyRender: (value, tableMeta) =>
          timeEntries.find((item) => item.id === tableMeta.rowData[0]).project.name
      }
    },
    {
      name: 'start',
      label: 'Hora inicio',
      options: {
        customBodyRender: (value, tableMeta) =>
          !tableMeta.rowData[tableMeta.columnIndex]
            ? null
            : format(
                parseISO(tableMeta.rowData[tableMeta.columnIndex]),
                'EEEE dd LLLL yyyy HH:mm',
                {
                  locale: es
                }
              )
      }
    },
    {
      name: 'stop',
      label: 'Hora fin',
      options: {
        customBodyRender: (value, tableMeta) =>
          !tableMeta.rowData[tableMeta.columnIndex]
            ? null
            : format(
                parseISO(tableMeta.rowData[tableMeta.columnIndex]),
                'EEEE dd LLLL yyyy HH:mm',
                {
                  locale: es
                }
              )
      }
    },
    {
      name: 'duration',
      label: 'Duración',
      options: {
        customBodyRender: (value, tableMeta) => {
          const dotIndex = tableMeta.rowData[tableMeta.columnIndex].indexOf('.')
          if (dotIndex !== -1)
            return tableMeta.rowData[tableMeta.columnIndex].substring(
              0,
              tableMeta.rowData[tableMeta.columnIndex].indexOf('.')
            )
          return tableMeta.rowData[tableMeta.columnIndex]
        }
      }
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        empty: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta) => {
          if (stopwatchIsRunning) {
            return null
          }
          return (
            <Grid container>
              <Button
                onClick={() => {
                  setTimeEntryCopy({
                    description: tableMeta.rowData[1],
                    workspace: timeEntries.find((item) => item.id === tableMeta.rowData[0])
                      .workspace,
                    project: timeEntries.find((item) => item.id === tableMeta.rowData[0]).project
                  })
                }}
                justIcon
                round
                color="linkedin"
                size="sm"
                disabled={stopwatchIsRunning}
                className={classes.btnAction}
              >
                <PlayArrowIcon />
              </Button>
            </Grid>
          )
        }
      }
    }
  ]

  const options = {
    print: false,
    filter: false,
    viewColumns: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowHover: true,
    selectableRows: 'none',
    textLabels
  }

  return (
    <TimeEntriesTable
      data={timeEntries}
      totalTime={totalTime}
      columns={columns}
      options={options}
      isLoading={isLoading}
    />
  )
}

TimeEntriesTableContainer.propTypes = {}

export default TimeEntriesTableContainer
