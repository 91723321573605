import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { URL_API_WORKSPACES, URL_API_TIMEENTRIES, URL_API_MANAGE_TIMEENTRIES } from 'constants/urls'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'

import useCustomSnackbar from 'components/useCustomSnackbar'

import DateFnsUtils from '@date-io/date-fns'
import { es } from 'date-fns/locale'
import { format } from 'date-fns'
import {
  reportTimeEntryFormReducer,
  defaultReportTimeEntryFormReducer
} from 'reducers/reportTimeEntryFormReducer'

import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  FormControl,
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core'

import useStyles from './reports.styles'

function TimeEntryForm({ id, onClose }) {
  const [state, dispatch] = useReducer(
    reportTimeEntryFormReducer,
    defaultReportTimeEntryFormReducer
  )
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const showNotification = useCustomSnackbar()

  const getProjects = async (workspaceId) => {
    try {
      const response = await axios.get(`${URL_API_WORKSPACES}/${workspaceId}/projects`)
      if (response.status === 200)
        dispatch({ type: 'SET_STATE', payload: { projects: response.data } })
    } catch (error) {
      console.log(error)
    }
  }

  const isValidForm = () => {
    if (!state.description.length) {
      showNotification('Campo Descripción es obligatorio', 'error')
      return false
    }
    if (!state.startAt) {
      showNotification('Campo Fecha Inicio es obligatorio', 'error')
      return false
    }
    if (!state.endAt) {
      showNotification('Campo Fecha Fin es obligatorio', 'error')
      return false
    }
    if (!state.selectedWorkspace.id.length) {
      showNotification('Campo Workspace es obligatorio', 'error')
      return false
    }
    if (!state.selectedProject.id.length) {
      showNotification('Campo Proyecto es obligatorio', 'error')
      return false
    }
    return true
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!isValidForm()) return

    try {
      setLoading(true)
      const response = await axios.put(`${URL_API_MANAGE_TIMEENTRIES}/${id}`, {
        id,
        userId: state.userId,
        description: state.description,
        start:
          typeof state.startAt === 'object'
            ? format(state.startAt, 'yyyy-MM-dd HH:mm')
            : state.startAt,
        stop:
          typeof state.endAt === 'object' ? format(state.endAt, 'yyyy-MM-dd HH:mm') : state.endAt,
        workspaceId: state.selectedWorkspace.id,
        projectId: state.selectedProject.id
      })
      if (response) setLoading(false)
      if (response.status === 204) {
        showNotification('Tarea actualizada correctamente')
        onClose()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    const getTimeEntry = async (timeEntryId) => {
      const response = await axios.get(`${URL_API_TIMEENTRIES}/${timeEntryId}`)
      if (response.status === 200)
        dispatch({
          type: 'SET_STATE',
          payload: {
            userId: response.data.userId,
            email: response.data.user.email,
            description: response.data.description,
            startAt: response.data.start,
            endAt: response.data.stop,
            selectedWorkspace: response.data.workspace,
            selectedProject: response.data.project
          }
        })
      getProjects(response.data.workspaceId)
    }
    const getWorkspaces = async () => {
      const response = await axios.get(URL_API_WORKSPACES)
      if (response.status === 200)
        dispatch({ type: 'SET_STATE', payload: { workspaces: response.data } })
    }
    getTimeEntry(id)
    getWorkspaces()
  }, [])

  return (
    <form noValidate onSubmit={onSubmit}>
      <Typography variant="h5" align="center">
        {state.email}
      </Typography>
      <FormControl fullWidth>
        <TextField
          required
          label="Descripción"
          value={state.description}
          onChange={(e) =>
            dispatch({ type: 'SET_STATE', payload: { description: e.target.value } })
          }
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <DateTimePicker
            id="startAt"
            name="startAt"
            label="Fecha Inicio"
            required
            format="yyyy-MM-dd HH:mm"
            invalidDateMessage="Fecha invalida"
            onChange={(date) => dispatch({ type: 'SET_STATE', payload: { startAt: date } })}
            value={state.startAt}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <DateTimePicker
            id="endAt"
            name="endAt"
            label="Fecha Fin"
            required
            format="yyyy-MM-dd HH:mm"
            invalidDateMessage="Fecha invalida"
            onChange={(date) => dispatch({ type: 'SET_STATE', payload: { endAt: date } })}
            value={state.endAt}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <Autocomplete
          options={state.workspaces}
          getOptionLabel={(option) => option.name}
          value={state.selectedWorkspace}
          onChange={(event, newValue) => {
            dispatch({
              type: 'SET_STATE',
              payload: { selectedWorkspace: newValue, selectedProject: { id: '', name: '' } }
            })
            getProjects(newValue.id)
          }}
          renderInput={(params) => <TextField required {...params} label="Workspace" fullWidth />}
          disableClearable
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <Autocomplete
          options={state.projects}
          getOptionLabel={(option) => option.name}
          value={state.selectedProject}
          onChange={(event, newValue) => {
            dispatch({
              type: 'SET_STATE',
              payload: { selectedProject: newValue }
            })
          }}
          renderInput={(params) => <TextField required {...params} label="Proyecto" fullWidth />}
          disableClearable
          disabled={!state.selectedWorkspace.id.length}
        />
      </FormControl>
      <Grid container justify="center" spacing={4} style={{ marginTop: 20 }}>
        <Grid item>
          <div className={classes.wrapper}>
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              Guardar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.wrapper}>
            <Button variant="contained" color="default" onClick={onClose}>
              Cancelar
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

TimeEntryForm.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default React.memo(TimeEntryForm)
