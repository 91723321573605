import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'

// Material ui core
import { Grid, Typography, CircularProgress } from '@material-ui/core'

function TimeEntriesTable({ data, totalTime, columns, options, isLoading }) {
  return (
    <Grid container justify="center" style={{ marginTop: '20px' }} spacing={2}>
      <Grid item md={6}>
        <Typography variant="h5" align="center">
          {isLoading ? 'Calculando...' : `Tiempo total de hoy ${totalTime}`}
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <MUIDataTable
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Typography variant="h6">
              Lista de Proyectos
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  )
}

TimeEntriesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalTime: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default TimeEntriesTable
