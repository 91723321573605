import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import hist from 'services/history'
import useAxiosInterceptor from 'components/useAxiosInterceptor'

import { AuthContextProvider } from 'contextApi/AuthContext'

import AdminLayout from 'layouts/Admin'
import PrivateRoute from 'components/PrivateRoute'
import LoginPage from 'views/Login'
import Oauth from 'views/Oauth'

const App = () => {
  useAxiosInterceptor()
  return (
    <Router history={hist}>
      <AuthContextProvider>
        <Switch>
          <PrivateRoute path="/admin" component={AdminLayout} />
          <Route path="/login" component={LoginPage} />
          <Route path="/oauth" component={Oauth} />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </AuthContextProvider>
    </Router>
  )
}

export default App
