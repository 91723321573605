import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'

export default makeStyles((theme) => ({
  ...switchStyles,
  ...customSelectStyle,
  icon: {
    marginBottom: '-6px',
    marginRight: '10px',
    cursor: 'pointer'
  },
  iconTask: {
    marginBottom: '-6px',
    marginRight: '10px'
  },
  iconAction: {
    marginRight: '10px'
  },
  active: {
    display: 'contents'
  },
  hide: {
    display: 'none'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  tableContainer: {
    marginTop: 20
  }
}))
