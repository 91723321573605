import React, { useContext, useEffect } from 'react'
import { EMPLOYEE } from 'constants/rolesConst'

import reportsContext from 'contextApi/ReportsContext'
import authContext from 'contextApi/AuthContext'

import DateFnsUtils from '@date-io/date-fns'
import { es } from 'date-fns/locale'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'

// Material ui
import {
  Grid,
  FormControlLabel,
  FormControl,
  Switch,
  Checkbox,
  TextField,
  CircularProgress,
  Button
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Material icons
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import useStyles from './reports.styles'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

function ReportForm() {
  const classes = useStyles()
  const { state, handleOnChange, getProjects, onSubmit, toExport } = useContext(reportsContext)
  const {
    userLoggedin: { role }
  } = useContext(authContext)

  useEffect(() => {
    handleOnChange({ target: { name: 'endAt', value: new Date() } })
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <Grid container spacing={2} justify="center">
        <Grid item md={3} sm={3} xs={12} lg={2}>
          <FormControl required className={classes.formControl} fullWidth>
            <DateTimePicker
              id="startAt"
              name="startAt"
              label="Desde"
              format="yyyy-MM-dd HH:mm"
              invalidDateMessage="Fecha invalida"
              onChange={(date) => handleOnChange({ target: { name: 'startAt', value: date } })}
              value={state.startAt}
            />
          </FormControl>
        </Grid>
        <Grid item md={3} sm={3} xs={12} lg={2}>
          <FormControl required className={classes.formControl} fullWidth>
            <DateTimePicker
              id="endAt"
              name="endAt"
              label="Hasta"
              format="yyyy-MM-dd HH:mm"
              invalidDateMessage="Fecha invalida"
              className={classes.datePicker}
              onChange={(date) => handleOnChange({ target: { name: 'endAt', value: date } })}
              value={state.endAt}
            />
          </FormControl>
        </Grid>
        <Grid item md={4} sm={4} xs={12} lg={3}>
          <Autocomplete
            id="selectedWorkspace"
            name="selectedWorkspace"
            value={state.selectedWorkspace}
            onChange={(e, value) => {
              handleOnChange({ target: { name: 'selectedWorkspace', value: value } })
              if (value) getProjects(value.id)
            }}
            options={state.workspaces}
            getOptionLabel={(option) => option.name || ' '}
            renderInput={(params) => <TextField {...params} label="Workspaces" fullWidth />}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12} lg={5}>
          <Autocomplete
            multiple
            id="selectedProjects"
            name="selectedProjects"
            value={state.selectedProjects}
            options={state.projects}
            onChange={(e, value) => {
              handleOnChange({ target: { name: 'selectedProjects', value: value } })
            }}
            disabled={!state.selectedWorkspace}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name || ' '}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </>
            )}
            renderInput={(params) => <TextField {...params} label="Proyectos" fullWidth />}
          />
        </Grid>

        {role !== EMPLOYEE && (
          <>
            <Grid item md={4} sm={4} xs={12} lg={5}>
              <Autocomplete
                multiple
                id="selectedUsers"
                name="selectedUsers"
                value={state.selectedUsers}
                options={state.users}
                onChange={(e, value) => {
                  handleOnChange({ target: { name: 'selectedUsers', value: value } })
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => option.email || ''}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.email}
                  </>
                )}
                renderInput={(params) => <TextField {...params} label="Usuarios" fullWidth />}
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12} lg={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.onlyBillable}
                    name="onlyBillable"
                    onChange={handleOnChange}
                    value={state.onlyBillable}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label="Solo facturables"
                style={{ marginTop: 10 }}
              />
            </Grid>
          </>
        )}
        <Grid item md={3} sm={3} xs={12} lg={1}>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={state.isLoading}
            >
              Buscar
            </Button>
            {state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Grid>
        {role !== EMPLOYEE ? (
          <Grid item md={3} sm={3} xs={12} lg={1}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={toExport}
                disabled={state.isLoading}
              >
                Exportar
              </Button>
              {state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </Grid>
        ) : null}
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
ReportForm.propTypes = {}

export default React.memo(ReportForm)
