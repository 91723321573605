import React from 'react'

// Material UI Core

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import TotalTimePerUser from 'components/TotalTimePerUser'
import ActiveTimesTracker from 'components/ActiveTimesTracker'

function Dashboar() {
  return (
    <GridContainer>
      <GridItem md={8} xs={12} sm={12}>
        <ActiveTimesTracker />
      </GridItem>
      <GridItem item md={4} xs={12} sm={12}>
        <TotalTimePerUser />
      </GridItem>
    </GridContainer>
  )
}

Dashboar.propTypes = {}

export default Dashboar
