import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { URL_API_USERS_TOTAL_HOURS_WEEK_BY_USER } from 'constants/urls'

// Material UI Core
import { Icon } from '@material-ui/core'

// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'

import useStyles from './dashboard.styles'

function DashboarEmployee() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(null)
  const [totalHoursOfWeek, setTotalHoursOfWeek] = useState('00:00:00')

  useEffect(() => {
    const getTotalTime = async () => {
      setIsLoading(true)
      const response = await axios.get(URL_API_USERS_TOTAL_HOURS_WEEK_BY_USER)

      if (response) setIsLoading(false)
      if (response.status === 200) setTotalHoursOfWeek(response.data)
    }
    getTotalTime()
    return () => {}
  }, [])
  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">
              <Icon>info_outline</Icon>
            </CardIcon>
            <p className={classes.cardCategory}>Tiempo total semana actual</p>
            <h3 className={classes.cardTitle}>{totalHoursOfWeek}</h3>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

DashboarEmployee.propTypes = {}

export default DashboarEmployee
