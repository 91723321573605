import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { URL_API_USERS_TOTAL_TIME } from 'constants/urls'
import { loadState } from 'services/localStorage'

// core components
import TotalTimePerUser from './TotalTimePerUser'

function TotalTimePerUserContainer() {
  const [users, setUsers] = useState([])

  const session = loadState()
  console.log('session', session)
  useEffect(() => {
    const getUsersTime = async () => {
      try {
        const result = await axios.get(URL_API_USERS_TOTAL_TIME, {
          headers: { Authorization: `Bearer ${session.accessToken}` }
        })

        if (result.status === 200) {
          setUsers(result.data.map((user) => [user.email, user.duration]))
        }
      } catch (error) {
        console.log(error)
      }
    }
    getUsersTime()
    return () => {}
  }, [])
  return <TotalTimePerUser data={users} />
}

TotalTimePerUserContainer.propTypes = {}

export default React.memo(TotalTimePerUserContainer)
