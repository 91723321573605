export const defaultReportTimeEntryFormReducer = {
  email: '',
  userId: '',
  description: '',
  startAt: '',
  endAt: '',
  selectedWorkspace: { id: '', name: '' },
  selectedProject: { id: '', name: '' },
  workspaces: [],
  projects: []
}

export const reportTimeEntryFormReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.payload }
    default:
      return state
  }
}
