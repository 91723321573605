import React from 'react'
import ReactDOM from 'react-dom'
import { SnackbarProvider } from 'notistack'
import App from './App'

import 'assets/scss/material-dashboard-pro-react.scss'

ReactDOM.render(
  <SnackbarProvider hideIconVariant maxSnack={4}>
    <App />
  </SnackbarProvider>,
  document.getElementById('root')
)
