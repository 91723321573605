import React from 'react'
import PropTypes from 'prop-types'

// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Table from 'components/Table/Table'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'

// Material UI Icons
import PeopleAlt from '@material-ui/icons/PeopleAlt'

import useStyles from './totalTimePerUser.styles'

function TotalTimePerUser({ data }) {
  const classes = useStyles()
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <PeopleAlt />
            </CardIcon>
            <p className={classes.cardCategory}>Horas por usuario</p>
            <h3 className={classes.cardTitle}>Hoy</h3>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={['Usuario', 'Tiempo']}
              tableData={data}
              customCellClasses={[classes.tdCell, classes.tdCell]}
              customClassesForCells={[0, 1]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

TotalTimePerUser.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired
}

export default TotalTimePerUser
