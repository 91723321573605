import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

export default makeStyles(() => ({
  ...styles,
  container: {
    paddingLeft: '15px',
    paddingRight: '15px'
  }
}))
