import React, { useContext } from 'react'
import cx from 'classnames'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import useAuthContext from 'contextApi/AuthContext'

// Constants
import { APP_NAME } from 'constants/applicationConsts'

// Services
import routesByRoleService from 'services/routesByRoleService'

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar'
import Footer from 'components/Footer/Footer'
import { TimeTrackerProvider } from 'contextApi/TimeTrackerContext'
import Sidebar from 'components/Sidebar/Sidebar'

import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle'

const image = require('assets/img/sidebar-2.jpg')
// const logo = require('assets/img/kronos_favicon_64x64.png')
const logo = require('assets/img/timeline.png')

let ps

const useStyles = makeStyles(styles)

export default function Dashboard(props) {
  const { userLoggedin } = useContext(useAuthContext)

  const history = useHistory()
  const { ...rest } = props
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [miniActive, setMiniActive] = React.useState(false)
  const color = 'blue'
  const bgColor = 'black'
  // const [hasImage, setHasImage] = React.useState(true);
  // styles
  const classes = useStyles()
  const mainPanelClasses = `${classes.mainPanel} ${cx({
    [classes.mainPanelSidebarMini]: miniActive,
    [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1
  })}`
  // ref for main panel div
  const mainPanel = React.createRef()

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      })
      document.body.style.overflow = 'hidden'
    }
    window.addEventListener('resize', resizeFunction)

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
      window.removeEventListener('resize', resizeFunction)
    }
  }, [history, mainPanel])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps'
  }
  const getActiveRoute = (routes) => {
    const activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else if (routes[i].withParam) {
        const routeSplit = routes[i].path.split('/')
        routeSplit.pop()
        const finalPath = routeSplit.join('/')
        if (window.location.href.indexOf(routes[i].layout + finalPath) !== -1) {
          return routes[i].name
        }
      } else if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name
      }
    }
    return activeRoute
  }
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === '/admin') {
        return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />
      }
      return null
    })
  }
  const sidebarMinimize = () => {
    setMiniActive(!miniActive)
  }
  const routesByRole = routesByRoleService(userLoggedin.role)
  return (
    <TimeTrackerProvider>
      <div className={classes.wrapper}>
        <Sidebar
          routes={routesByRole}
          logoText={APP_NAME}
          logo={logo}
          userEmail={userLoggedin.email}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize}
            miniActive={miniActive}
            brandText={getActiveRoute(routesByRole)}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div>
              <Switch>
                {getRoutes(routesByRole)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
          {getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    </TimeTrackerProvider>
  )
}
