import React, { useReducer, useContext, useEffect } from 'react'
import axios from 'axios'
import { URL_API_WORKSPACES, URL_API_USERSWORKSPACES, URL_API_TIMEENTRIES } from 'constants/urls'

import { timeEntryReducer, timeEntryInitialState } from 'reducers/timeEntryReducer'
import timeTrackerContext from 'contextApi/TimeTrackerContext'

// Material ui core
import { Grid, FormControl, TextField, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Material ui core
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'

// Core components
import Button from 'components/CustomButtons/Button'
import StopwatchCounter from 'components/StopwatchCounter'

import { loadState } from 'services/localStorage'
import styles from './timeEntry.styles'

function TimeEntry() {
  const [state, dispatch] = useReducer(timeEntryReducer, timeEntryInitialState)
  const session = loadState()

  const classes = styles()
  const {
    stopwatchIsRunning,
    setStopwatchIsRunning,
    timeEntryCopy,
    setDefaultTimeEntryCopy,
    setSecondsCounter,
    handleStartStopwatchInterval,
    initializeCounter
  } = useContext(timeTrackerContext)

  const saveNewEntry = async (initialData) => {
    const data =
      initialData && initialData.project.id.length
        ? {
            description: initialData.description,
            workspaceId: initialData.workspace.id,
            projectId: initialData.project.id
          }
        : {
            description: state.timeEntry.description,
            workspaceId: state.timeEntry.workspace.id,
            projectId: state.timeEntry.project.id
          }

    try {
      dispatch({ type: 'IS_LOADING', payload: true })
      const result = await axios.post(URL_API_TIMEENTRIES, data)
      if (result) dispatch({ type: 'IS_LOADING', payload: false })
      if (result.status === 201) {
        dispatch({ type: 'SET_TIME_ENTRY', name: 'id', value: result.data.id })
        return true
      }
      return false
    } catch (error) {
      dispatch({ type: 'IS_LOADING', payload: false })
      return false
    }
  }

  const updateTimeEntry = async (stop) => {
    const data = {
      id: state.timeEntry.id,
      description: state.timeEntry.description,
      workspaceId: state.timeEntry.workspace.id,
      projectId: state.timeEntry.project.id
    }

    if (stopwatchIsRunning && stop) data.stop = new Date()

    try {
      dispatch({ type: 'IS_LOADING', payload: true })
      const result = await axios.put(`${URL_API_TIMEENTRIES}/${state.timeEntry.id}`, data)
      if (result) dispatch({ type: 'IS_LOADING', payload: false })

      if (result.status === 204) {
        if (stopwatchIsRunning && stop) {
          dispatch({ type: 'SET_DEFAULT_TIME_ENTRY' })
        }
        return true
      }
      return false
    } catch (error) {
      dispatch({ type: 'IS_LOADING', payload: false })
      return false
    }
  }
  const getWorkspaces = async () => {
    try {
      const result = await axios.get(URL_API_USERSWORKSPACES, {
        headers: { Authorization: `Bearer ${session.accessToken}` }
      })
      if (result.status === 200) dispatch({ type: 'SET_WORKSPACES', payload: result.data })
    } catch (error) {
      console.log(error)
    }
  }

  const getProjects = async (workspaceId) => {
    try {
      const result = await axios.get(`${URL_API_WORKSPACES}/${workspaceId}/projects`)
      if (result.status === 200) dispatch({ type: 'SET_PROJECTS', payload: result.data })
    } catch (error) {
      console.log(error)
    }
  }

  const getTimeEntryActive = async () => {
    try {
      dispatch({ type: 'IS_LOADING', payload: true })
      const result = await axios.get(`${URL_API_TIMEENTRIES}/active`, {
        headers: { Authorization: `Bearer ${session.accessToken}` }
      })
      if (result.data && result.data.id) {
        dispatch({
          type: 'SET_STATE',
          payload: {
            timeEntry: {
              id: result.data.id,
              description: result.data.description,
              workspace: result.data.workspace,
              project: result.data.project,
              start: result.data.start
            }
          }
        })
        getProjects(result.data.workspace.id)
        setStopwatchIsRunning(true)
      }
      if (result) dispatch({ type: 'IS_LOADING', payload: false })
    } catch (error) {
      dispatch({ type: 'IS_LOADING', payload: false })
      console.log(error.response)
    }
  }

  const handleClickPlayStopwatch = async () => {
    if (stopwatchIsRunning && (await updateTimeEntry(true))) {
      clearInterval(state.intervalId)
      setSecondsCounter(0)
      dispatch({
        type: 'SET_STATE',
        payload: {
          intervalId: null
        }
      })
      setStopwatchIsRunning(!stopwatchIsRunning)
    }

    if (!stopwatchIsRunning && (await saveNewEntry())) {
      handleStartStopwatchInterval()
      setStopwatchIsRunning(!stopwatchIsRunning)
    }
  }

  const handleOnBlur = () => {
    if (stopwatchIsRunning && state.timeEntry.project.id.length) updateTimeEntry()
  }

  useEffect(() => {
    getWorkspaces()
    getTimeEntryActive()
    return () => {
      clearInterval(state.intervalId)
    }
  }, [])

  useEffect(() => {
    if (state.startStopwatchWithDate) {
      handleStartStopwatchInterval(state.startStopwatchWithDate)
      dispatch({
        type: 'SET_STATE',
        payload: {
          startStopwatchWithDate: null
        }
      })
    }

    const saveNewtimeEntry = async () => {
      if (await saveNewEntry(timeEntryCopy)) {
        handleStartStopwatchInterval()
        setStopwatchIsRunning(!stopwatchIsRunning)
      }
    }
    if (timeEntryCopy.project.id.length) {
      dispatch({ type: 'SET_ALL_TIME_ENTRY', payload: { ...timeEntryCopy } })
      setDefaultTimeEntryCopy(true)
      saveNewtimeEntry()
    }
    return () => {
      clearInterval(state.intervalId)
    }
  }, [state.startStopwatchWithDate, timeEntryCopy])

  const getButton = () => {
    return (
      <div className={classes.wrapper}>
        <Button
          onClick={handleClickPlayStopwatch}
          justIcon
          round
          color={stopwatchIsRunning ? 'danger' : 'success'}
          className={classes.btnPlay}
          disabled={!state.timeEntry.project.id.length || state.isLoading}
        >
          {stopwatchIsRunning ? <StopIcon /> : <PlayArrowIcon />}
        </Button>
        {state.isLoading && <CircularProgress size={26} className={classes.buttonProgress} />}
      </div>
    )
  }

  return (
    <Grid container justify="center" spacing={4}>
      <Grid item md={5} xs={12} sm={6} lg={5}>
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            required
            name="description"
            label="Descripción de la tarea"
            value={state.timeEntry.description}
            onChange={({ target }) =>
              dispatch({ type: 'SET_TIME_ENTRY', name: target.name, value: target.value })
            }
            autoComplete="off"
            onBlur={handleOnBlur}
            multiline
            rowsMax="4"
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={6} lg={2}>
        <Autocomplete
          options={state.workspaces}
          getOptionLabel={(option) => option.name}
          value={state.timeEntry.workspace}
          onChange={(event, newValue) => {
            dispatch({
              type: 'SET_WORKSPACE',
              value: newValue
            })
            getProjects(newValue.id)
          }}
          renderInput={(params) => <TextField {...params} label="Workspace" fullWidth />}
          disableClearable
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6} lg={3}>
        <Autocomplete
          options={state.projects}
          getOptionLabel={(option) => option.name || ' '}
          disabled={!state.timeEntry.workspace.name.length}
          value={state.timeEntry.project}
          onChange={(event, newValue) =>
            dispatch({
              type: 'SET_TIME_ENTRY',
              name: 'project',
              value: newValue
            })
          }
          renderInput={(params) => <TextField {...params} label="Proyecto" fullWidth />}
          disableClearable
          onBlur={handleOnBlur}
        />
      </Grid>
      <Grid item md={1} xs={12} sm={6} lg={1}>
        {getButton()}
        <StopwatchCounter initializeCounter={initializeCounter} style={{ marginLeft: '-10px' }} />
      </Grid>
    </Grid>
  )
}

TimeEntry.propTypes = {}

export default React.memo(TimeEntry)
