export default {
  body: {
    noMatch: 'No hay registros',
    toolTip: 'Ordenar',
    columnHeaderTooltip: (column) => `Ordenar por ${column.label}`
  },
  pagination: {
    next: 'Página siguiente',
    previous: 'Página anterior',
    rowsPerPage: 'filas por página:',
    displayRows: 'de'
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Descargar CSV'
  }
}
