// Constants
import { routesConst } from 'constants/routesConsts'
import { SUPER_ADMIN, ADMIN } from 'constants/rolesConst'

// Components
import Dashboard from 'views/Dashboard'
import PermissionsView from 'views/Permissions'
import UsersView from 'views/Users'
import RolesView from 'views/Roles'
import WorkspacesView from 'views/Workspaces'
import ProjectsView from 'views/Projects'
import TimeTracker from 'views/TimeTracker'
import Reports from 'views/Reports'

// Icons
import Home from '@material-ui/icons/Home'
import LockIcon from '@material-ui/icons/Lock'
import GroupIcon from '@material-ui/icons/Group'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import SecurityIcon from '@material-ui/icons/Security'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import TimerIcon from '@material-ui/icons/AccessTime'
import CardTravelIcon from '@material-ui/icons/CardTravel'
import BarChartIcon from '@material-ui/icons/BarChart'

const layout = '/admin'

export default {
  [SUPER_ADMIN.toLowerCase()]: [
    {
      path: routesConst.projects,
      name: 'Proyectos',
      component: ProjectsView,
      layout,
      icon: AssignmentTurnedInIcon
    },
    {
      path: routesConst.workspaces,
      name: 'Workspaces',
      component: WorkspacesView,
      layout,
      icon: CardTravelIcon
    },
    {
      collapse: true,
      name: 'Seguridad',
      icon: SecurityIcon,
      state: 'pageCollapse',
      views: [
        {
          path: routesConst.users,
          name: 'Usuarios',
          component: UsersView,
          layout,
          icon: GroupIcon
        },
        {
          path: routesConst.roles,
          name: 'Roles',
          component: RolesView,
          layout,
          icon: AssignmentIndIcon
        },
        {
          path: routesConst.permissions,
          name: 'Permisos',
          component: PermissionsView,
          layout,
          icon: LockIcon
        }
      ]
    }
  ],
  [ADMIN.toLowerCase()]: [
    {
      path: routesConst.projects,
      name: 'Proyectos',
      component: ProjectsView,
      layout,
      icon: AssignmentTurnedInIcon
    },
    {
      path: routesConst.workspaces,
      name: 'Workspaces',
      component: WorkspacesView,
      layout,
      icon: CardTravelIcon
    },
    {
      path: routesConst.users,
      name: 'Usuarios',
      component: UsersView,
      layout,
      icon: GroupIcon
    }
  ],
  empleado: [],
  all: [
    {
      path: routesConst.dashboard,
      name: 'Dashboard',
      component: Dashboard,
      layout,
      icon: Home
    },
    {
      path: routesConst.timeTracker,
      name: 'Timer',
      component: TimeTracker,
      layout,
      icon: TimerIcon
    },
    {
      path: routesConst.reports,
      name: 'Reporte',
      component: Reports,
      layout,
      icon: BarChartIcon
    }
  ]
}
