import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  container: {
    // width: 185,
    // height: 65
  },
  timer: {
    fontWeight: 'bold',
    fontSize: '20px',
    // padding: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  btnPlay: {
    marginTop: '-10px'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -12
  }
}))
