import React, { useEffect } from 'react'
import { URL_BACKEND_LOGIN } from 'constants/urls'

export default function LoginPage() {
  useEffect(() => {
    console.log('URL_BACKEND_LOGIN', URL_BACKEND_LOGIN)

    window.location.replace(URL_BACKEND_LOGIN)
    return () => {}
  }, [])
  return <></>
}
