import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import styles from './stopwatchCounter.styles'

function StopwatchCounter({ initializeCounter, ...rest }) {
  const classes = styles()
  return (
    // <div style={{ width: '140px', display: 'inline-block' }}>
    <Typography className={classes.timer} {...rest} align="center">
      {initializeCounter()}
    </Typography>
    // </div>
  )
}

StopwatchCounter.propTypes = {
  initializeCounter: PropTypes.func.isRequired
}

export default StopwatchCounter
