import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import textLabels from 'utils/MUIDataTableTextLabels'
import SweetAlert from 'react-bootstrap-sweetalert'

// Constants
import { URL_API_USERS as urlApiModel } from 'constants/urls'

// Material ui core
import { Grid, CircularProgress, Typography, Tooltip } from '@material-ui/core'

// Template Components
import useCustomSnackbar from 'components/useCustomSnackbar'
import Button from 'components/CustomButtons/Button'
import ModalContainer from 'components/ModalContainer'
import CustomToolbar from 'components/CustomToolbarMuiDatatable'

// Material ui icons
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Delete'
import BlockIcon from '@material-ui/icons/Block'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import ModelForm from './UserForm'
import AddUserToWorkspace from './AddUserToWorkspace'

import styles from './users.styles'
import DisableUser from './DisableUser'

export default function Roles() {
  // state
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [modelIdToEdit, setModelIdToEdit] = useState(null)
  const [opeModalModel, setOpenModalModel] = useState(false)
  const [alert, setAlert] = useState(null)
  const [opeModalDisableUser, setOpeModalDisableUser] = useState(false)
  const [emailOfUserToDisable, setEmailOfUserToDisable] = useState('')
  const [disableUser, setDisableUser] = useState(false)
  const [openModaluserToWorkspace, setOpenModaluserToWorkspace] = useState(false)
  const [userToAddWorkspace, setUserToAddWorkspace] = useState(null)

  const showNotification = useCustomSnackbar()

  const classes = styles()
  const getModels = async () => {
    setIsLoading(true)
    const result = await axios.get(urlApiModel)
    if (result) setIsLoading(false)
    if (result.status === 200) setData(result.data)
  }
  useEffect(() => {
    getModels()
  }, [])

  const successDelete = async (modelId) => {
    setAlert(null)
    const response = await axios.delete(`${urlApiModel}/${modelId}`)
    if (response.status === 204) {
      showNotification('Registro eliminado correctamente')
      getModels()
    }
  }

  const cancelDetele = () => {
    setAlert(null)
  }

  const warningWithConfirmAndCancelMessage = (modelId) => {
    setAlert(
      <SweetAlert
        warning
        title="Está seguro que desea eliminar este asesor?"
        onConfirm={() => successDelete(modelId)}
        onCancel={() => cancelDetele()}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
        cancelBtnCssClass={`${classes.button} ${classes.danger}`}
        confirmBtnText="Aceptar"
        cancelBtnText="Cancelar"
        showCancel
      />
    )
  }
  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
        print: false,
        filter: false,
        sort: false
      }
    },
    {
      name: 'disabledAt',
      options: {
        display: false,
        download: false,
        print: false,
        filter: false,
        sort: false
      }
    },
    {
      name: 'firstName',
      label: 'Nombre'
    },
    {
      name: 'lastName',
      label: 'Apellido'
    },
    {
      name: 'email',
      label: 'Email'
    },
    {
      name: '',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        empty: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta) => {
          const disabledAt = tableMeta.rowData[1]
          return (
            <Grid container>
              <Tooltip title="Asignar workspaces" aria-label="Asignar workspaces">
                <Button
                  onClick={() => {
                    setUserToAddWorkspace({
                      id: tableMeta.rowData[0],
                      email: tableMeta.rowData[4]
                    })
                    setOpenModaluserToWorkspace(true)
                  }}
                  justIcon
                  round
                  color="linkedin"
                  size="sm"
                  className={classes.buttonAction}
                >
                  <AssignmentTurnedInIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Editar" aria-label="Editar">
                <Button
                  onClick={() => {
                    setModelIdToEdit(tableMeta.rowData[0])
                    setOpenModalModel(true)
                  }}
                  justIcon
                  round
                  color="linkedin"
                  size="sm"
                  className={classes.buttonAction}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip
                title={!disabledAt ? 'Inhabilitar usuario' : 'habilitar usuario'}
                aria-label="Estado de usuario"
              >
                <Button
                  onClick={() => {
                    setModelIdToEdit(tableMeta.rowData[0])
                    setOpeModalDisableUser(true)
                    setEmailOfUserToDisable(tableMeta.rowData[4])
                    setDisableUser(!tableMeta.rowData[1])
                  }}
                  justIcon
                  round
                  color={!disabledAt ? 'danger' : 'linkedin'}
                  size="sm"
                  className={classes.buttonAction}
                >
                  {!disabledAt ? <BlockIcon /> : <LockOpenIcon />}
                </Button>
              </Tooltip>
              <Tooltip title="Eliminar usuario" aria-label="Eliminar usuario">
                <Button
                  onClick={() => {
                    warningWithConfirmAndCancelMessage(tableMeta.rowData[0])
                  }}
                  justIcon
                  round
                  color="danger"
                  size="sm"
                  className={classes.buttonAction}
                >
                  <CloseIcon />
                </Button>
              </Tooltip>
            </Grid>
          )
        }
      }
    }
  ]
  const options = {
    print: false,
    filter: false,
    viewColumns: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowHover: true,
    selectableRows: 'none',
    textLabels,
    customToolbar: () => {
      return (
        <CustomToolbar
          tooltip="Agregar usuario"
          onClick={() => {
            setModelIdToEdit(null)
            setOpenModalModel(true)
          }}
        />
      )
    }
  }

  const handleCloseModalModel = () => {
    setOpenModalModel(false)
    getModels()
  }

  const handleCloseModalDisableUser = () => {
    setOpeModalDisableUser(false)
    getModels()
  }

  const handleCloseModaluserToWorkspace = () => {
    setOpenModaluserToWorkspace(false)
    getModels()
  }
  return (
    <>
      <Grid container justify="center">
        <Grid item md={12} xs={12}>
          <MUIDataTable
            title={
              <Typography variant="h6">
                Lista de Usuario
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </Typography>
            }
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <ModalContainer
        title={`${modelIdToEdit ? 'Actualizar' : 'Crear'} Usuario`}
        open={opeModalModel}
        onClose={handleCloseModalModel}
        size="sm"
      >
        <ModelForm modelId={modelIdToEdit} onCancel={handleCloseModalModel} />
      </ModalContainer>
      <ModalContainer
        title={`${disableUser ? 'Inhabilitar' : 'Habilitar'} Usuario`}
        open={opeModalDisableUser}
        onClose={handleCloseModalDisableUser}
        size="sm"
      >
        <DisableUser
          modelId={modelIdToEdit}
          email={emailOfUserToDisable}
          disable={disableUser}
          onCancel={handleCloseModalDisableUser}
        />
      </ModalContainer>
      {userToAddWorkspace && (
        <ModalContainer
          title="Asignar workspaces"
          open={openModaluserToWorkspace}
          onClose={handleCloseModaluserToWorkspace}
          size="sm"
        >
          <AddUserToWorkspace
            userToAddWorkspaces={userToAddWorkspace}
            onCancel={handleCloseModaluserToWorkspace}
          />
        </ModalContainer>
      )}
      {alert}
    </>
  )
}
