import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { URL_API_TIMEENTRIES_WITHOUT_STOP } from 'constants/urls'
import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'
import { loadState } from 'services/localStorage'

import ActiveTimesTracker from './ActiveTimesTracker'

function ActiveTimesTrackerContainer() {
  const [timeEntriesWithoutStop, setTimeEntriesWithoutStop] = useState([])

  const session = loadState()

  const formatDate = (date) => {
    const today = new Date().getDay()
    const dateParsed = parseISO(date)
    if (today === dateParsed.getDay()) {
      return `Hoy a las ${format(dateParsed, 'HH:mm')}`
    }

    return format(dateParsed, 'EEEE HH:mm', {
      locale: es
    })
  }

  useEffect(() => {
    const getTimeEntriesWithoutStop = async () => {
      const response = await axios.get(`${URL_API_TIMEENTRIES_WITHOUT_STOP}`, {
        headers: { Authorization: `Bearer ${session.accessToken}` }
      })

      if (response.status === 200) {
        setTimeEntriesWithoutStop(
          response.data.map((user) => [
            user.user.email,
            user.project.name,
            user.description,
            formatDate(user.start)
          ])
        )
      }
    }
    getTimeEntriesWithoutStop()
    return () => {}
  }, [])

  return <ActiveTimesTracker timeEntriesWithoutStop={timeEntriesWithoutStop} />
}

export default React.memo(ActiveTimesTrackerContainer)
