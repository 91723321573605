export const baseRouteBackoffice = '/admin'

export const routesConst = {
  dashboard: '/dashboard',
  timeTracker: '/timeTracker',
  permissions: '/permissions',
  users: '/users',
  roles: '/roles',
  workspaces: '/workspaces',
  projects: '/projects',
  reports: '/reports',
  me: '/me'
}

export const routesLink = {
  login: '/login',
  dashboard: `${baseRouteBackoffice}${routesConst.dashboard}`,
  timeTracker: `${baseRouteBackoffice}${routesConst.timeTracker}`,
  reports: `${baseRouteBackoffice}${routesConst.reports}`,
  workspaces: `${baseRouteBackoffice}${routesConst.workspaces}`,
  me: `${baseRouteBackoffice}${routesConst.me}`
}
