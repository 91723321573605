// create function for save in local storage
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (err) {
    // Ignore write errors.
  }
}

// create function for get from local storage
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

// create function for remove from local storage
export const removeState = () => {
  try {
    localStorage.removeItem('state')
  } catch (err) {
    // Ignore write errors.
  }
}
