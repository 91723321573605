import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'

// Material ui core
import { Grid, Typography } from '@material-ui/core'

// Template components
import useCustomSnackbar from 'components/useCustomSnackbar'
import ButtonLoading from 'components/ButtonLoading'
import Button from 'components/CustomButtons/Button'

// Constants
import { URL_API_USERS as urlApiModel } from 'constants/urls'
import styles from './users.styles'

const DisableUser = ({ modelId, email, disable, onCancel }) => {
  // states
  const [isLoading, setIsLoading] = useState(false)

  const classes = styles()
  const showNotification = useCustomSnackbar()

  useEffect(() => {}, [])

  const onSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const result = await axios.put(`${urlApiModel}/${modelId}/disableUser`, {
        id: modelId,
        disable
      })
      if (result.status === 204) {
        showNotification('Registro actualizado correctamente')
        onCancel()
      }
      if (result) setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      <Grid container className={classes.root} justify="center">
        <Typography>
          {`Estas seguro que deseas ${disable ? `inhabilitar` : 'habilitar'} el usuario`}
        </Typography>
        &nbsp;
        <Typography style={{ fontWeight: 'bold' }}>{email}</Typography>
        <Typography>?</Typography>
      </Grid>
      <Grid container justify="center" style={{ marginTop: '20px' }}>
        <ButtonLoading type="submit" isLoading={isLoading} color="linkedin" label="Si" />
        <div className={classes.wrapper}>
          <Button color="danger" disabled={isLoading} onClick={onCancel}>
            no
          </Button>
        </div>
      </Grid>
    </form>
  )
}
DisableUser.propTypes = {
  modelId: PropTypes.string,
  email: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
}
DisableUser.defaultProps = {
  modelId: ''
}
export default DisableUser
