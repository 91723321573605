import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'

// Material ui core
import { Grid, FormControlLabel, Switch, Typography } from '@material-ui/core'

// Template components
import useCustomSnackbar from 'components/useCustomSnackbar'
import CustomInput from 'components/CustomInput/CustomInput'
import ButtonLoading from 'components/ButtonLoading'
import Button from 'components/CustomButtons/Button'

// Constants
import { URL_API_USERS as urlApiModel, URL_API_ROLES } from 'constants/urls'
import styles from './users.styles'

const defaultModel = {
  firstName: '',
  lastName: '',
  email: '',
  role: ''
}
const UserForm = ({ modelId, onCancel }) => {
  // states
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState(defaultModel)
  const [roles, setRoles] = useState([])

  const classes = styles()
  const showNotification = useCustomSnackbar()

  const getRoles = async () => {
    setIsLoading(true)
    try {
      const result = await axios.get(URL_API_ROLES)
      if (result.status === 200) setRoles(result.data.map((item) => ({ ...item, checked: false })))
      if (result) setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getRoles()
    if (modelId) {
      const getModel = async () => {
        setIsLoading(true)
        try {
          const result = await axios.get(`${urlApiModel}/${modelId}`)
          if (result.status === 200) setModel(result.data)
          if (result) setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
        }
      }
      getModel()
    }
  }, [modelId])

  const handleChangeRole = (target) => {
    const { checked, value } = target

    let roleSelected = {}
    const newRoles = roles.map((item) => {
      if (item.id === value) {
        roleSelected = item
        return { ...item, checked: checked }
      }
      return { ...item, checked: false }
    })
    setModel({ ...model, roleId: roleSelected.id, role: roleSelected })
    setRoles([...newRoles])
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    if (modelId) {
      try {
        const result = await axios.put(`${urlApiModel}/${modelId}`, model)
        if (result.status === 204) {
          showNotification('Registro actualizado correctamente')
          onCancel()
        }
        if (result) setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    } else {
      try {
        const result = await axios.post(urlApiModel, model)
        if (result.status === 201) {
          showNotification('Registro creado correctamente')
          onCancel()
        }
        if (result) setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
  }
  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      <Grid container className={classes.root} justify="center">
        <CustomInput
          labelText="Nombre"
          id="firstName"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: model.firstName,
            onChange: ({ target }) => setModel({ ...model, firstName: target.value })
          }}
        />
        <CustomInput
          labelText="Apellidos"
          id="lastName"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: !model.lastName ? '' : model.lastName,
            onChange: ({ target }) => setModel({ ...model, lastName: target.value })
          }}
        />
        <CustomInput
          labelText="Email"
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: model.email,
            onChange: ({ target }) => setModel({ ...model, email: target.value })
          }}
        />
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} md={12} sm={12} className={classes.marginDivider}>
          <Typography variant="h4" align="center">
            Asignar rol
          </Typography>
        </Grid>
        {roles.map((role) => {
          return (
            <React.Fragment key={role.id}>
              <FormControlLabel
                control={
                  <Switch
                    checked={role.checked || model.role.id === role.id}
                    onChange={(event) => handleChangeRole(event.target)}
                    value={role.id}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={role.name}
              />
            </React.Fragment>
          )
        })}
      </Grid>
      <Grid container justify="center" className={classes.marginDivider}>
        <ButtonLoading
          type="submit"
          isLoading={isLoading}
          color="linkedin"
          label={modelId ? 'Actualizar' : 'Aceptar'}
        />
        <div className={classes.wrapper}>
          <Button color="danger" disabled={isLoading} onClick={onCancel}>
            Cacelar
          </Button>
        </div>
      </Grid>
    </form>
  )
}

UserForm.propTypes = {
  modelId: PropTypes.string,
  onCancel: PropTypes.func.isRequired
}

UserForm.defaultProps = {
  modelId: null
}

export default UserForm
