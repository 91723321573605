import React from 'react'

import { CircularProgress, Grid, Typography } from '@material-ui/core'

const logo = require('assets/img/timeline.png')

function Splash() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ marginTop: '50px' }}
    >
      <img src={logo} alt="logo" style={{ marginBottom: 10 }} />
      <Typography variant="h5" align="center" style={{ marginBottom: 10 }}>
        Espera por favor, cargando Kronos...
      </Typography>
      <CircularProgress />
    </Grid>
  )
}

Splash.propTypes = {}

export default Splash
