import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { URL_API_TIMEENTRIES } from 'constants/urls'

import useCustomSnackbar from 'components/useCustomSnackbar'
import {
  reportTimeEntryFormReducer,
  defaultReportTimeEntryFormReducer
} from 'reducers/reportTimeEntryFormReducer'

import {
  FormControl,
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core'

import useStyles from './reports.styles'

function DeleteTimeEntry({ id, onClose }) {
  const [loading, setLoading] = useState(false)

  const [state, dispatch] = useReducer(
    reportTimeEntryFormReducer,
    defaultReportTimeEntryFormReducer
  )

  const classes = useStyles()
  const showNotification = useCustomSnackbar()

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await axios.delete(`${URL_API_TIMEENTRIES}/${id}`)
      if (response) setLoading(false)
      if (response.status === 204) {
        showNotification('Tarea eliminada correctamente')
        onClose()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    const getTimeEntry = async (timeEntryId) => {
      const response = await axios.get(`${URL_API_TIMEENTRIES}/${timeEntryId}`)
      if (response.status === 200)
        dispatch({
          type: 'SET_STATE',
          payload: {
            email: response.data.user.email,
            description: response.data.description,
            startAt: response.data.start.substring(0, 19).replace('T', ' '),
            endAt: response.data.stop.substring(0, 19).replace('T', ' '),
            selectedWorkspace: response.data.workspace.name,
            selectedProject: response.data.project.name
          }
        })
    }
    getTimeEntry(id)
  }, [id])

  return (
    <form noValidate onSubmit={onSubmit}>
      <Typography variant="h5" align="center">
        {state.email}
      </Typography>
      <FormControl fullWidth>
        <TextField required label="Descripción" value={state.description} readOnly />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <TextField
          required
          label="Fecha Inicio"
          value={state.startAt}
          readOnly
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <TextField
          required
          label="Fecha Fin"
          value={state.endAt}
          readOnly
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <TextField required label="Workspace" value={state.selectedWorkspace} readOnly />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <TextField required label="Proyecto" value={state.selectedProject} readOnly />
      </FormControl>
      <Grid container justify="center" spacing={4} style={{ marginTop: 20 }}>
        <Grid item>
          <div className={classes.wrapper}>
            <Button variant="contained" color="secondary" type="submit" disabled={loading}>
              Eliminar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.wrapper}>
            <Button variant="contained" color="default" onClick={onClose}>
              Cancelar
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

DeleteTimeEntry.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default React.memo(DeleteTimeEntry)
