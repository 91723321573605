import React, { useEffect, useContext } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useHistory, useLocation } from 'react-router-dom'
import useAuthContext from 'contextApi/AuthContext'

import Splash from 'components/Splash'

// Constants
import { API_GET_TOKEN } from 'constants/urls'
import { routesLink } from 'constants/routesConsts'
import { EMPLOYEE } from 'constants/rolesConst'

export default function Oauth() {
  const { setLoggedin } = useContext(useAuthContext)
  const history = useHistory()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  const email = params.get('email')
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const showSnackbar = (message, variant = 'success') => {
      if (!message) throw new Error('Parametro message es requerido')
      enqueueSnackbar(message, {
        variant,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })
    }

    if (code && email) {
      axios.defaults.withCredentials = true
      axios
        .post(API_GET_TOKEN, {
          code,
          email
        })
        .then(function(result) {
          if (result.data.accessToken) {
            setLoggedin({
              firstName: result.data.firstName,
              email: result.data.email,
              role: result.data.role,
              picture: result.data.picture,
              accessToken: result.data.accessToken
            })

            if (result.data.role === EMPLOYEE) {
              history.push(routesLink.timeTracker)
            } else {
              history.push(routesLink.dashboard)
            }
          }
        })
        .catch(function(err) {
          if (err.response && err.response.data && err.response.data.message) {
            showSnackbar(err.response.data.message, 'error')
          } else {
            showSnackbar('Ocurrió un error', 'error')
          }
          history.push(routesLink.login)
        })
    }
    return () => {}
  }, [code, email, history, enqueueSnackbar])

  return <Splash />
}
