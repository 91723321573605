/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import styles from 'assets/jss/material-dashboard-pro-react/components/footerStyle.js'

import { COMPANY_NAME, COMPANY_URL } from 'constants/applicationConsts'

const useStyles = makeStyles(styles)

export default function Footer(props) {
  const classes = useStyles()
  const { fluid, white } = props
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  })
  var anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white
    })
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  })
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p className={classes.right}>
          V. 2.0.0 &nbsp; &copy; {1900 + new Date().getYear()}{' '}
          <a href={COMPANY_URL} className={anchor} target="_blank">
            {COMPANY_NAME}
          </a>
          {', Desarrollo de software con pasión.'}
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
}
