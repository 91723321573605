import { makeStyles } from '@material-ui/core/styles'
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  formControl: {
    marginTop: '11px'
  },
  inputBorder: {
    borderColor: '#FF0000 !important',
    borderWidth: '1px !important'
  },
  buttonAction: {
    marginLeft: 10
  },
  ...buttonStyle
}))
