// import React, { Fragment, useEffect } from 'react'
import axios from 'axios'

import useCustomSnackbar from 'components/useCustomSnackbar'
import { loadState } from 'services/localStorage'

export default function useAxiosInterceptor() {
  const showNotification = useCustomSnackbar()

  const insterceptor = () => {
    axios.interceptors.request.use(
      function(config) {
        const session = loadState()
        if (session && session.accessToken) {
          // eslint-disable-next-line dot-notation
          axios.defaults.headers.common['Authorization'] = `Bearer ${session.accessToken}`
        }
        const newConfig = { ...config, withCredentials: true }
        return newConfig
      },
      function(error) {
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use(
      function(response) {
        return response
      },
      function(error) {
        if (!error.response && !error.response.status) {
          showNotification('Error de conexión', 'error')
          return Promise.reject(error)
        }
        switch (error.response.status) {
          case 400:
            if (error.response.data.message) {
              showNotification(error.response.data.message, 'error')
            } else if (error.response.data.errors) {
              const errorApi = error.response.data.errors
              Object.keys(errorApi).forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(errorApi, key)) {
                  const msgErrors = errorApi[key]
                  msgErrors.map((err) => showNotification(err, 'error'))
                }
              })
            } else {
              showNotification('(400) Solicitud no valida', 'error')
            }
            break
          case 401:
            window.location = '/login'
            break
          case 403:
            // showNotification('(403) No estas autorizado para esta acción', 'error')
            break
          case 404:
            showNotification('(404) El recurso no existe', 'error')
            break
          case 405:
            showNotification('(405) Método no permitido', 'error')
            break
          case 415:
            showNotification('(415) Media type no soportado', 'error')
            break
          case 500:
            showNotification('(500) Ocurrió un error en el servidor', 'error')
            break
          default:
            break
        }
        return Promise.reject(error)
      }
    )
  }

  insterceptor()
}
