import React, { useEffect, useReducer } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { addSeconds, format, parseISO, differenceInSeconds } from 'date-fns'

import contextApiReducer, { contextApiReducerDefault } from 'reducers/timeTrackerReducer'
import { URL_API_TIMEENTRIES } from 'constants/urls'
import { loadState } from 'services/localStorage'

const TimeTrackerContext = React.createContext()

export const TimeTrackerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(contextApiReducer, contextApiReducerDefault)

  const session = loadState()

  const setIconStop = () => {
    const favicon = document.getElementById('qcode-favicon')
    favicon.href = '/stop.png'
  }

  const setKronosIcon = () => {
    const favicon = document.getElementById('qcode-favicon')
    favicon.href = '/kronos_favicon_128x128.png'
  }
  const getTimeEntryActive = async () => {
    const result = await axios.get(`${URL_API_TIMEENTRIES}/active`, {
      headers: { Authorization: `Bearer ${session.accessToken}` }
    })
    if (result.data && result.data.id) {
      dispatch({
        type: 'SET_ALL_STATE',
        payload: {
          startStopwatchWithDate: result.data.start,
          shouldGetActiveTime: false
        }
      })
    }
  }
  const handleStartStopwatchInterval = (dateToStart) => {
    if (dateToStart) {
      const differenceInSecondsValue = differenceInSeconds(new Date(), parseISO(dateToStart))
      dispatch({
        type: 'SET_STATE',
        name: 'secondsCounter',
        value: differenceInSecondsValue
      })
    }

    if (state.intervalId) clearInterval(state.intervalId)

    dispatch({
      type: 'SET_ALL_STATE',
      payload: {
        intervalId: setInterval(() => {
          dispatch({ type: 'SET_SECONDS_COUNTER_ADD_ONE' })
        }, 1000),
        secondsCounter: dateToStart ? differenceInSeconds(new Date(), parseISO(dateToStart)) : 0,
        startAt: dateToStart ? parseISO(dateToStart) : new Date()
      }
    })
  }

  const initializeCounter = () => {
    const helperDate = addSeconds(new Date().setHours(0, 0, 0, 0), state.secondsCounter)
    return format(helperDate, 'HH:mm:ss')
  }

  useEffect(() => {
    if (state.shouldGetActiveTime) {
      getTimeEntryActive()
    }
    if (state.startStopwatchWithDate) {
      handleStartStopwatchInterval(state.startStopwatchWithDate)
      dispatch({
        type: 'SET_ALL_STATE',
        payload: {
          startStopwatchWithDate: null,
          stopwatchIsRunning: true
        }
      })
    }

    if (state.stopwatchIsRunning) {
      setIconStop()
    } else {
      if (state.intervalId) clearInterval(state.intervalId)
      setKronosIcon()
    }
  }, [state.stopwatchIsRunning, state.startStopwatchWithDate])

  const title = `${initializeCounter()} - Kronos`
  document.title = title

  return (
    <TimeTrackerContext.Provider
      value={{
        stopwatchIsRunning: state.stopwatchIsRunning,
        setStopwatchIsRunning: (isRunning) => {
          dispatch({ type: 'SET_STATE', name: 'stopwatchIsRunning', value: isRunning })
        },
        timeEntryCopy: state.timeEntryCopy,
        setTimeEntryCopy: (timeEntryCopy) => {
          dispatch({ type: 'SET_TIME_ENTRY_COPY', payload: timeEntryCopy })
        },
        setDefaultTimeEntryCopy: () => {
          dispatch({
            type: 'SET_DEFAULT_TIME_ENTRY_COPY',
            payload: { ...contextApiReducerDefault.timeEntryCopy }
          })
        },
        secondsCounter: state.secondsCounter,
        setSecondsCounter: (seconds) => {
          dispatch({
            type: 'SET_STATE',
            name: 'secondsCounter',
            value: seconds
          })
        },
        addOneSecond: () => {
          dispatch({ type: 'SET_SECONDS_COUNTER_ADD_ONE' })
        },
        handleStartStopwatchInterval,
        initializeCounter
      }}
    >
      {children}
    </TimeTrackerContext.Provider>
  )
}

TimeTrackerProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const TimeTrackerConsumer = TimeTrackerContext.Consumer

export default TimeTrackerContext
