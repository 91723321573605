import React, { useContext } from 'react'
import reportContext from 'contextApi/ReportsContext'
// Core components
import ModalContainer from 'components/ModalContainer'
import ReportsForm from './ReportForm'
import ReportList from './ReportList'
import TimeEntryForm from './TimeEntryForm'
import DeleteTimeEntry from './DeleteTimeEntry'

function Reports() {
  const {
    onCloseTimeEntryForm,
    onCloseModalTimeEntryDelete,
    state: { openModalTimeEntryForm, timeEntryFormId, openModalDeleteTimeEntry }
  } = useContext(reportContext)

  return (
    <>
      <ReportsForm />
      <ReportList />
      <ModalContainer
        open={openModalTimeEntryForm}
        title="Editar tarea"
        onClose={onCloseTimeEntryForm}
      >
        <TimeEntryForm id={timeEntryFormId} onClose={onCloseTimeEntryForm} />
      </ModalContainer>
      <ModalContainer
        open={openModalDeleteTimeEntry}
        title="Eliminar tarea"
        onClose={onCloseModalTimeEntryDelete}
      >
        <DeleteTimeEntry id={timeEntryFormId} onClose={onCloseModalTimeEntryDelete} />
      </ModalContainer>
    </>
  )
}

export default Reports
