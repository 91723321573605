import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import authContext from 'contextApi/AuthContext'

import Splash from 'components/Splash'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isLoggedIn, logout } = useContext(authContext)

  useEffect(() => {
    if (isLoggedIn === false) {
      logout()
    }
  }, [isLoggedIn, logout])

  if (isLoggedIn === false) logout()

  if (isLoggedIn === true) return <Route path={path} component={Component} {...rest} />

  return <Splash />
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
}

export default PrivateRoute
