import { defaultFont, dangerColor, whiteColor } from 'assets/jss/material-dashboard-pro-react'

import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle'

const adminNavbarLinksStyle = (theme) => ({
  ...customDropdownStyle(theme),
  linkText: {
    zIndex: '4',
    ...defaultFont,
    fontSize: '14px',
    margin: '0!important',
    textTransform: 'none'
  },
  buttonLink: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '5px 15px 0',
      width: 'auto',
      height: 'auto',
      '& svg': {
        width: '30px',
        height: '24px',
        marginRight: '19px',
        marginLeft: '3px'
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        width: '30px',
        fontSize: '24px',
        lineHeight: '30px',
        marginRight: '19px',
        marginLeft: '3px'
      }
    }
  },
  top: {
    zIndex: '4'
  },
  links: {
    width: '20px',
    height: '20px',
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '30px',
      height: '30px',
      color: 'inherit',
      opacity: '0.8',
      marginRight: '16px',
      marginLeft: '-5px'
    }
  },
  notifications: {
    zIndex: '4',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '5px',
      border: `1px solid ${whiteColor}`,
      right: '5px',
      fontSize: '9px',
      background: dangerColor[0],
      color: whiteColor,
      minWidth: '16px',
      height: '16px',
      borderRadius: '10px',
      textAlign: 'center',
      lineHeight: '14px',
      verticalAlign: 'middle',
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      ...defaultFont,
      fontSize: '14px',
      marginRight: '8px'
    }
  },
  managerClasses: {
    [theme.breakpoints.up('md')]: {
      display: 'inline-block'
    }
  },
  headerLinksSvg: {
    width: '20px !important',
    height: '20px !important'
  },
  photo: {
    width: '41px !important',
    height: '41px !important',
    overflow: 'hidden',
    marginRight: '11px',
    borderRadius: '50%',
    marginLeft: '10px',
    verticalAlign: 'middle',
    border: '0',
    cursor: 'pointer'
  },
  photoMobile: {
    width: '25px !important',
    height: '25px !important',
    overflow: 'hidden',
    marginRight: '16px',
    borderRadius: '50%',
    marginLeft: '13px',
    verticalAlign: 'middle',
    border: '0'
  }
})

export default adminNavbarLinksStyle
