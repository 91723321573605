import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import textLabels from 'utils/MUIDataTableTextLabels'
import SweetAlert from 'react-bootstrap-sweetalert'
import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'

// Constants
import { URL_API_PROJECTS as modelApi } from 'constants/urls'

// Material ui core
import { Grid, CircularProgress, Typography } from '@material-ui/core'

// Template Components
import useCustomSnackbar from 'components/useCustomSnackbar'
import Button from 'components/CustomButtons/Button'
import ModalContainer from 'components/ModalContainer'

// Material ui icons
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'

import CustomToolbar from 'components/CustomToolbarMuiDatatable'
import ModelForm from './ProjectForm'

import styles from './project.styles'

export default function Permissions() {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [modelIdToEdit, setModelIdToEdit] = useState(null)
  const [opeModalModel, setOpenModalModel] = useState(false)
  const [alert, setAlert] = useState(null)
  const showNotification = useCustomSnackbar()

  const classes = styles()
  const getModels = async () => {
    setIsLoading(true)
    try {
      const result = await axios.get(modelApi)
      if (result) setIsLoading(false)
      if (result.status === 200) setData(result.data)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getModels()
  }, [])

  const successDelete = async (modelId) => {
    setAlert(null)
    const response = await axios.delete(`${modelApi}/${modelId}`)
    if (response.status === 204) {
      showNotification('Registro eliminado correctamente')
      getModels()
    }
  }

  const cancelDetele = () => {
    setAlert(null)
  }

  const warningWithConfirmAndCancelMessage = (modelId) => {
    setAlert(
      <SweetAlert
        warning
        title="Está seguro que desea eliminar este asesor?"
        onConfirm={() => successDelete(modelId)}
        onCancel={() => cancelDetele()}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
        cancelBtnCssClass={`${classes.button} ${classes.danger}`}
        confirmBtnText="Aceptar"
        cancelBtnText="Cancelar"
        showCancel
      />
    )
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
        print: false,
        filter: false,
        sort: false
      }
    },
    {
      name: 'workspaceId',
      label: 'Workspace',
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        empty: true,
        customBodyRender: (value, tableMeta) =>
          data.find((item) => item.id === tableMeta.rowData[0]).workspace.name
      }
    },
    {
      name: 'name',
      label: 'Nombre'
    },
    {
      name: 'estimatedHours',
      label: 'Horas estimadas'
    },
    {
      name: 'deadLine',
      label: 'Fecha de finalización',
      options: {
        customBodyRender: (value, tableMeta) =>
          !tableMeta.rowData[tableMeta.columnIndex]
            ? null
            : format(
                parseISO(tableMeta.rowData[tableMeta.columnIndex]),
                'EEEE dd LLLL yyyy HH:mm',
                {
                  locale: es
                }
              )
      }
    },
    {
      name: 'isBillable',
      label: 'Es facturable',
      options: {
        customBodyRender: (value, tableMeta) =>
          tableMeta.rowData[tableMeta.columnIndex] ? 'Si' : 'No'
      }
    },
    {
      name: '',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        empty: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid container>
              <Button
                onClick={() => {
                  setModelIdToEdit(tableMeta.rowData[0])
                  setOpenModalModel(true)
                }}
                justIcon
                round
                color="linkedin"
                size="sm"
                className={classes.buttonAction}
              >
                <EditIcon />
              </Button>
              <Button
                onClick={() => {
                  warningWithConfirmAndCancelMessage(tableMeta.rowData[0])
                }}
                justIcon
                round
                color="danger"
                size="sm"
                className={classes.buttonAction}
              >
                <CloseIcon />
              </Button>
            </Grid>
          )
        }
      }
    }
  ]
  const options = {
    print: false,
    filter: false,
    viewColumns: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowHover: true,
    selectableRows: 'none',
    textLabels,
    customToolbar: () => {
      return (
        <CustomToolbar
          tooltip="Agregar proyecto"
          onClick={() => {
            setModelIdToEdit(null)
            setOpenModalModel(true)
          }}
        />
      )
    }
  }

  const handleCloseModalModel = () => {
    setOpenModalModel(false)
    getModels()
  }
  return (
    <>
      {/* <HeaderButton
        label="Nuevo proyecto"
        onClick={() => {
          setModelIdToEdit(null)
          setOpenModalModel(true)
        }}
      /> */}
      <Grid container justify="center">
        <Grid item md={12} xs={12}>
          <MUIDataTable
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Typography variant="h6">
                Lista de Proyectos
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </Typography>
            }
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <ModalContainer
        title={`${modelIdToEdit ? 'Actualizar' : 'Agregar'} Proyecto`}
        open={opeModalModel}
        onClose={handleCloseModalModel}
        size="xs"
      >
        <ModelForm modelId={modelIdToEdit} onCancel={handleCloseModalModel} />
      </ModalContainer>
      {alert}
    </>
  )
}
