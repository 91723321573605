export const API_URL_BASE =
  process.env.NODE_ENV === 'development'
    ? 'https://back.qcode.net:5000'
    : 'https://api-kronos.qcode.app'
export const API_BASE = `${API_URL_BASE}/api`
export const URL_BACKEND_LOGIN = `${API_URL_BASE}/login?provider=Google`
export const API_LOGOUT = `${API_URL_BASE}/logout`
export const API_GET_TOKEN = `${API_URL_BASE}/Sessions/GetToken`

export const URL_API_DASHBOARD = `${API_BASE}/Dashboard`

export const URL_API_PERMISSIONS = `${API_BASE}/Permissions`
export const URL_API_ROLES = `${API_BASE}/Roles`
export const URL_API_USERS = `${API_BASE}/Users`
export const URL_API_WORKSPACES = `${API_BASE}/Workspaces`
export const URL_API_PROJECTS = `${API_BASE}/Projects`
export const URL_API_PROJECTS_BY_WORKSPACES = `${API_BASE}/Projects/byWorkspaces`
export const URL_API_USERSWORKSPACES = `${API_BASE}/UsersWorkspaces`
export const URL_API_TIMEENTRIES = `${API_BASE}/TimeEntries`
export const URL_API_MANAGE_TIMEENTRIES = `${API_BASE}/ManageTimeEntries`
export const URL_API_TIMEENTRIES_WITHOUT_STOP = `${URL_API_TIMEENTRIES}/WithoutStopping`
export const URL_API_USERS_TOTAL_TIME = `${URL_API_USERS}/TotalTime`
export const URL_API_USERS_TOTAL_HOURS_WEEK_BY_USER = `${URL_API_USERS}/TotalHoursOfTheWeekByUser`
export const URL_REPORT = `${API_BASE}/reports`
