import React from 'react'
import { ReportsProvider } from 'contextApi/ReportsContext'
import Reports from './Reports'

export default function index() {
  return (
    <ReportsProvider>
      <Reports />
    </ReportsProvider>
  )
}
