import React, { useContext } from 'react'

import authContext from 'contextApi/AuthContext'

import { EMPLOYEE } from 'constants/rolesConst'

import DashboarEmplyee from './DashboarEmployee'
import Dashboar from './Dashboar'

export default function DashboardContainer() {
  const {
    userLoggedin: { role }
  } = useContext(authContext)
  return role === EMPLOYEE ? <DashboarEmplyee /> : <Dashboar />
}
