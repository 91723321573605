import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'

// Material ui core
import { Grid, FormControlLabel, Switch } from '@material-ui/core'

// Template components
import useCustomSnackbar from 'components/useCustomSnackbar'
import CustomInput from 'components/CustomInput/CustomInput'
import ButtonLoading from 'components/ButtonLoading'
import Button from 'components/CustomButtons/Button'

// Constants
import { URL_API_PERMISSIONS, URL_API_ROLES } from 'constants/urls'

import styles from './permissions.styles'

const defaultModel = {
  name: '',
  roles: []
}
function PermissionForm({ modelId, onCancel }) {
  // states
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState(defaultModel)
  const [roles, setRoles] = useState([])

  const classes = styles()
  const showNotification = useCustomSnackbar()

  const getRoles = async () => {
    const result = await axios.get(URL_API_ROLES)
    if (result.status === 200) setRoles(result.data)
  }

  useEffect(() => {
    getRoles()
    if (modelId) {
      const getModel = async () => {
        setIsLoading(true)
        try {
          const result = await axios.get(`${URL_API_PERMISSIONS}/${modelId}`)
          if (result.status === 200) setModel(result.data)
          if (result) setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
        }
      }
      getModel()
    }
  }, [modelId])

  const handleChangeRole = (target) => {
    const { checked, value } = target
    const role = roles.filter((item) => item.id === value)[0]
    if (checked) {
      setModel({ ...model, roles: [...model.roles, role] })
    } else {
      setModel({
        ...model,
        roles: model.roles.filter((roleModel) => roleModel.id !== role.id)
      })
    }
  }
  const onSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      if (modelId) {
        const result = await axios.put(`${URL_API_PERMISSIONS}/${modelId}`, model)
        if (result.status === 204) {
          showNotification('Registro actualizado correctamente')
          onCancel()
        }
        if (result) setIsLoading(false)
      } else {
        const result = await axios.post(URL_API_PERMISSIONS, model)
        if (result.status === 201) {
          showNotification('Registro creado correctamente')
          onCancel()
        }
        if (result) setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }
  const control = (roleId) => (
    <Switch
      checked={model.roles.some((roleModel) => roleModel.id === roleId)}
      onChange={(event) => handleChangeRole(event.target)}
      value={roleId}
      classes={{
        switchBase: classes.switchBase,
        checked: classes.switchChecked,
        thumb: classes.switchIcon,
        track: classes.switchBar
      }}
    />
  )
  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Nombre"
            id="name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: model.name,
              onChange: ({ target }) => setModel({ ...model, name: target.value })
            }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.root} justify="center">
        {roles.map((role) => {
          return (
            <React.Fragment key={role.id}>
              <FormControlLabel
                control={control(role.id)}
                classes={{
                  label: classes.label
                }}
                label={role.name}
              />
            </React.Fragment>
          )
        })}
      </Grid>
      <Grid container justify="center">
        <ButtonLoading
          type="submit"
          isLoading={isLoading}
          color="linkedin"
          label={modelId ? 'Actualizar' : 'Aceptar'}
        />
        <div className={classes.wrapper}>
          <Button color="danger" disabled={isLoading} onClick={onCancel}>
            Cacelar
          </Button>
        </div>
      </Grid>
    </form>
  )
}
PermissionForm.propTypes = {
  modelId: PropTypes.string,
  onCancel: PropTypes.func.isRequired
}

PermissionForm.defaultProps = {
  modelId: ''
}

export default PermissionForm
