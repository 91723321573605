import { startOfWeek } from 'date-fns'

export const reportsInitialState = {
  isLoading: false,
  timeEntryFormId: '',
  startAt: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endAt: new Date(),
  onlyBillable: false,
  selectedWorkspace: { id: '', name: '' },
  selectedProjects: [],
  selectedUsers: [],
  workspaces: [],
  projects: [],
  users: [],
  timeEntries: [],
  openModalTimeEntryForm: false,
  openModalDeleteTimeEntry: false
}

export default function(state, action) {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.payload }
    default:
      return state
  }
}
