import React from 'react'
import PropTypes from 'prop-types'

// Template Components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import Table from 'components/Table/Table'

// Material UI Icons
import Warning from '@material-ui/icons/Warning'

import styles from './activeTimesTracker.styles'

function ActiveTimesTracker({ timeEntriesWithoutStop }) {
  const classes = styles()

  return (
    <div>
      <Card>
        <CardHeader color="danger" stats icon>
          <CardIcon color="danger">
            <Warning />
          </CardIcon>
          <p className={classes.cardCategory}>Timers activos</p>
          <h3 className={classes.cardTitle}>{timeEntriesWithoutStop.length}</h3>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Table
                tableHead={['Usuario', 'Proyecto', 'Descripción', 'Inicio']}
                tableData={timeEntriesWithoutStop}
                customCellClasses={[classes.tdCell, classes.tdCell, classes.tdCell, classes.tdCell]}
                customClassesForCells={[0, 1, 2, 3]}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  )
}

ActiveTimesTracker.propTypes = {
  timeEntriesWithoutStop: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired
}

export default ActiveTimesTracker
