import React, { useEffect, useReducer, useState, useContext } from 'react'
import axios from 'axios'
import { startOfWeek, format } from 'date-fns'
import qs from 'qs'
import PropTypes from 'prop-types'

import authContext from 'contextApi/AuthContext'

import reportsReducer, { reportsInitialState } from 'reducers/reportsReducer'
import {
  URL_API_USERSWORKSPACES,
  URL_API_WORKSPACES,
  URL_REPORT,
  URL_API_USERS
} from 'constants/urls'

import { EMPLOYEE } from 'constants/rolesConst'

const ReportsContext = React.createContext()

export default ReportsContext

export const ReportsProvider = ({ children }) => {
  const [isToExport, setIsToExport] = useState(false)
  const [state, dispatch] = useReducer(reportsReducer, reportsInitialState)
  const {
    userLoggedin: { role }
  } = useContext(authContext)

  const getProjects = async (workspaceId) => {
    try {
      const result = await axios.get(`${URL_API_WORKSPACES}/${workspaceId}/projects`)
      if (result.status === 200) dispatch({ type: 'SET_STATE', payload: { projects: result.data } })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnChange = ({ target }) => {
    const { name } = target
    const value = name === 'onlyBillable' ? target.checked : target.value

    if (target.name === 'selectedWorkspace') {
      dispatch({ type: 'SET_STATE', payload: { [name]: value, selectedProjects: [] } })
    } else {
      dispatch({ type: 'SET_STATE', payload: { [name]: value } })
    }
  }

  const onSubmit = async () => {
    dispatch({ type: 'SET_STATE', payload: { isLoading: true } })
    const data = {
      startAt: state.startAt,
      endAt: state.endAt,
      toExport: isToExport,
      selectedProjects: state.selectedProjects.map((item) => item.id),
      onlyBillable: state.onlyBillable,
      UsersId: state.selectedUsers.map((item) => item.id)
    }

    if (state.selectedWorkspace && state.selectedWorkspace.id.length) {
      data.selectedWorkspace = [state.selectedWorkspace.id]
    }

    try {
      const response = await axios.get(`${URL_REPORT}`, {
        params: data,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        },
        responseType: isToExport ? 'blob' : 'application/json'
      })
      if (response) dispatch({ type: 'SET_STATE', payload: { isLoading: false } })
      if (response.status === 200) {
        if (isToExport) {
          if (response.data.size === 0) return
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          const fileName = `reporteKronos-${format(new Date(), 'yyyyMMdd_HHmmss')}.xlsx`
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        } else {
          dispatch({ type: 'SET_STATE', payload: { timeEntries: response.data } })
        }
      }
    } catch (error) {
      dispatch({ type: 'SET_STATE', payload: { isLoading: false } })
      console.log(error)
    }
  }

  const toExport = () => {
    setIsToExport(true)
  }

  const handleOnCangeProjects = async (e, value) => {
    dispatch({ type: 'SET_STATE', payload: { selectedProjects: value } })
  }

  const onCloseTimeEntryForm = () => {
    dispatch({
      type: 'SET_STATE',
      payload: { openModalTimeEntryForm: false, timeEntryFormId: '' }
    })
    onSubmit()
  }

  const onCloseModalTimeEntryDelete = () => {
    dispatch({
      type: 'SET_STATE',
      payload: { openModalDeleteTimeEntry: false, timeEntryFormId: '' }
    })
    onSubmit()
  }

  const handleOpenModalTimeEntryForm = (timeEntryFormId) => {
    dispatch({
      type: 'SET_STATE',
      payload: { openModalTimeEntryForm: true, timeEntryFormId: timeEntryFormId }
    })
  }

  const handleOpenModalDeleteTimeEntry = (id) => {
    dispatch({
      type: 'SET_STATE',
      payload: { openModalDeleteTimeEntry: true, timeEntryFormId: id }
    })
  }

  useEffect(() => {
    if (!isToExport) {
      dispatch({
        type: 'SET_STATE',
        payload: { endAt: new Date(), startAt: startOfWeek(new Date(), { weekStartsOn: 1 }) }
      })
    }

    const getUsers = async () => {
      const url = URL_API_USERS
      try {
        const response = await axios.get(url)
        if (response.status === 200) {
          dispatch({ type: 'SET_STATE', payload: { users: response.data } })
        }
      } catch (error) {
        console.log('Error consultando usuarios')
      }
    }

    if (state.users.length === 0) getUsers()

    const getWorkspaces = async () => {
      const url = role === EMPLOYEE ? URL_API_USERSWORKSPACES : URL_API_WORKSPACES
      const response = await axios.get(url)
      if (response.status === 200) {
        handleOnChange({ target: { name: 'workspaces', value: response.data } })
      }
    }

    if (state.workspaces.length === 0) getWorkspaces()

    if (isToExport) {
      onSubmit()
      setIsToExport(false)
    }
  }, [isToExport])

  return (
    <ReportsContext.Provider
      value={{
        state,
        handleOnChange,
        handleOnCangeProjects,
        getProjects,
        onSubmit,
        toExport,
        onCloseTimeEntryForm,
        handleOpenModalTimeEntryForm,
        handleOpenModalDeleteTimeEntry,
        onCloseModalTimeEntryDelete
      }}
    >
      {children}
    </ReportsContext.Provider>
  )
}
ReportsProvider.propTypes = {
  children: PropTypes.element.isRequired
}
export const ReportsConsumer = ReportsContext.Consumer
