import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
import Cookie from 'js-cookie'
import { COOKIE_NAME_ISLOGEDIN, COOKIE_NAME_USER, COOKIE_DOMAIN } from 'constants/cookiesConsts'
import defaultAvatar from 'assets/img/default-avatar.png'
import { API_LOGOUT } from 'constants/urls'

import { routesLink } from 'constants/routesConsts'
import { saveState, loadState, removeState } from 'services/localStorage'

const AuthContext = React.createContext()
export default AuthContext

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(null)

  const history = useHistory()

  const persistUserData = (userData) => {
    saveState({ ...userData, isLoggedIn: true })
  }

  const recoverUserData = () => {
    const session = loadState()
    if (session && session.isLoggedIn) {
      return {
        id: session.id || '',
        firstName: session.firstName || '',
        email: session.email || '',
        picture: session.picture || defaultAvatar,
        role: session.role || null,
        accessToken: session.accessToken || null
      }
    }
    return null
  }

  const setLoggedin = (userLoggedin) => {
    persistUserData(userLoggedin)
    setUser(userLoggedin)
    setIsLoggedIn(true)
  }

  const logout = () => {
    removeState()
    history.push(routesLink.login)
  }

  useEffect(() => {
    const userData = recoverUserData()

    if (userData) {
      setUser(userData)
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
    return () => {}
  }, [])

  return (
    <AuthContext.Provider
      value={{
        setLoggedin,
        userLoggedin: user,
        isLoggedIn,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
AuthContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const AuthContextConsumer = AuthContext.Consumer
