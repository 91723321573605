export const isValidEmail = (email) => {
  const re = /^\S+@([a-zA-Z0-9]+)\.{1}([a-zA-Z]){2,3}(\.[a-zA-Z]{2})?$/
  if (email === '') return true
  return re.test(email)
}
export const onlyNumber = (event) => {
  const key = window.event ? event.which : event.keyCode
  if (key < 48 || key > 57) {
    event.preventDefault()
    event.stopPropagation()
  }
}

export const coinValue = (value) => {
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP'
  })
    .format(parseInt(value, 10) === 'NaN' ? 0 : value)
    .replace(/\D00$/, '')
}

/**
 * Add two string time values (HH:mm:ss) with javascript
 *
 * Usage:
 *  > addTimes('04:20:10', '21:15:10');
 *  > "25:35:20"
 *  > addTimes('04:35:10', '21:35:10');
 *  > "26:10:20"
 *  > addTimes('30:59', '17:10');
 *  > "48:09:00"
 *  > addTimes('19:30:00', '00:30:00');
 *  > "20:00:00"
 *
 * @param {String} startTime  String time format
 * @param {String} endTime  String time format
 * @returns {String}
 */
export const addTimes = (timesToAdd) => {
  if (timesToAdd.length < 2) return false
  return timesToAdd.reduce((accomulator, currentTime) => {
    // startTime, endTime
    const times = [0, 0, 0]
    const max = times.length

    const a = (accomulator || '').split(':')
    const b = (currentTime || '').split(':')

    // normalize time values
    for (let i = 0; i < max; i += 1) {
      a[i] = Number.isNaN(parseInt(a[i], 10)) ? 0 : parseInt(a[i], 10)
      b[i] = Number.isNaN(parseInt(b[i], 10)) ? 0 : parseInt(b[i], 10)
    }

    // store time values
    for (let i = 0; i < max; i += 1) {
      times[i] = a[i] + b[i]
    }

    let hours = times[0]
    let minutes = times[1]
    let seconds = times[2]

    if (seconds >= 60) {
      const m = Math.floor(seconds / 60)
      minutes += m
      seconds -= 60 * m
    }

    if (minutes >= 60) {
      const h = Math.floor(minutes / 60)
      hours += h
      minutes -= 60 * h
    }

    const hoursFormatted = hours.toString().padStart(2, '0')
    const minutesFormatted = minutes.toString().padStart(2, '0')
    const secondsFormatted = seconds.toString().padStart(2, '0')
    return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`
  })
}
