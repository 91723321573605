import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import { es } from 'date-fns/locale'

// Material ui core
import { Grid, FormControl, Select, InputLabel, MenuItem, TextField } from '@material-ui/core'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
// import DateFnsUtils from "@date-io/moment"

// Template components
import useCustomSnackbar from 'components/useCustomSnackbar'
import ButtonLoading from 'components/ButtonLoading'
import Button from 'components/CustomButtons/Button'

// Constants
import { URL_API_PROJECTS as modelApi, URL_API_WORKSPACES } from 'constants/urls'

import styles from './project.styles'

const defaultModel = {
  name: '',
  estimatedHours: '',
  workspaceId: '',
  deadLine: null,
  isBillable: ''
}

function ProjectForm({ modelId, onCancel }) {
  // states
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState(defaultModel)
  const [workspaces, setWorkspaces] = useState([])

  const classes = styles()
  const showNotification = useCustomSnackbar()

  const getWorkspaces = async () => {
    setIsLoading(true)
    try {
      const result = await axios.get(URL_API_WORKSPACES)
      if (result.status === 200) setWorkspaces(result.data)
      if (result) setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getWorkspaces()
    if (modelId) {
      const getModel = async () => {
        setIsLoading(true)
        try {
          const result = await axios.get(`${modelApi}/${modelId}`)
          if (result.status === 200)
            setModel((prev) => {
              return { ...prev, ...result.data }
            })
          if (result) setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
        }
      }
      getModel()
    }
  }, [modelId])

  const formIsValid = () => {
    if (!model.name || !model.workspaceId) return false
    return true
  }
  const requestSubmit = async () => {
    setIsLoading(true)
    try {
      const config = {
        method: !modelId ? 'post' : 'put',
        url: !modelId ? modelApi : `${modelApi}/${modelId}`,
        data: model
      }

      const result = await axios(config)
      if (result.status === 204 || result.status === 201) {
        showNotification('El registro se guardó correctamente')
        onCancel()
      }
      if (result) setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    if (formIsValid() === false) return undefined
    requestSubmit()
    return undefined
  }

  const handleOnChange = ({ target: { name, value } }) => {
    setModel({ ...model, [name]: value })
  }

  const isNumber = (e) => {
    if (Number.isNaN(Number(e.key))) e.preventDefault()
  }
  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      <FormControl required className={classes.formControl} fullWidth>
        <InputLabel>Workspace</InputLabel>
        <Select
          id="workspaceId"
          name="workspaceId"
          value={model.workspaceId}
          onChange={handleOnChange}
          disabled={isLoading}
        >
          {workspaces.map((workspace) => (
            <MenuItem value={workspace.id} key={workspace.id}>
              {workspace.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} fullWidth>
        <TextField
          required
          name="name"
          label="Nombre"
          value={model.name}
          onChange={handleOnChange}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth>
        <TextField
          name="estimatedHours"
          label="Tiempo estimado (horas)"
          value={model.estimatedHours}
          onChange={handleOnChange}
          onKeyPress={isNumber}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <DateTimePicker
            id="deadLine"
            name="deadLine"
            label="Fecha de entrega"
            format="yyyy-MM-dd HH:mm"
            invalidDateMessage="Fecha invalida"
            className={classes.datePicker}
            onChange={(date) => handleOnChange({ target: { name: 'deadLine', value: date } })}
            value={model.deadLine}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <FormControl required className={classes.formControl} fullWidth>
        <InputLabel>Es facturable?</InputLabel>
        <Select
          id="isBillable"
          name="isBillable"
          value={model.isBillable}
          onChange={handleOnChange}
          disabled={isLoading}
        >
          <MenuItem value="">Seleccione</MenuItem>
          <MenuItem value="true">Si</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </FormControl>
      <Grid container justify="center">
        <ButtonLoading
          type="submit"
          isLoading={isLoading}
          color="linkedin"
          label={modelId ? 'Actualizar' : 'Aceptar'}
          disabled={formIsValid() === false}
        />
        <div className={classes.wrapper}>
          <Button color="danger" disabled={isLoading} onClick={onCancel}>
            Cacelar
          </Button>
        </div>
      </Grid>
    </form>
  )
}

ProjectForm.propTypes = {
  modelId: PropTypes.string,
  onCancel: PropTypes.func.isRequired
}

ProjectForm.defaultProps = {
  modelId: ''
}
export default ProjectForm
