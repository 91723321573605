import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'

// Material ui core
import { Grid, FormControlLabel, Switch, Typography } from '@material-ui/core'

// Template components
import useCustomSnackbar from 'components/useCustomSnackbar'
import ButtonLoading from 'components/ButtonLoading'
import Button from 'components/CustomButtons/Button'

// Constants
import { URL_API_USERSWORKSPACES as urlApiModel, URL_API_WORKSPACES } from 'constants/urls'

import styles from './users.styles'

const AddUserToWorkspace = ({ userToAddWorkspaces, onCancel }) => {
  // states
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState([])
  const [workspaces, setWorkspaces] = useState([])

  const classes = styles()
  const showNotification = useCustomSnackbar()

  const getWorkspaces = async () => {
    setIsLoading(true)
    try {
      const result = await axios.get(URL_API_WORKSPACES)
      if (result.status === 200) setWorkspaces(result.data)
      if (result) setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getWorkspaces()
    const getModel = async () => {
      setIsLoading(true)
      try {
        const result = await axios.get(`${urlApiModel}/${userToAddWorkspaces.id}`)
        if (result.status === 200) setModel(result.data)
        if (result) setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
    getModel()
  }, [userToAddWorkspaces.id])

  const handleChangeWorkspace = (target) => {
    const { checked, value } = target
    const index = model.indexOf(value)

    if (checked && index === -1) {
      setModel([...model, value])
    } else if (!checked && index > -1) {
      setModel(model.filter((item) => item !== value))
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const result = await axios.put(`${urlApiModel}/${userToAddWorkspaces.id}`, model)
      if (result.status === 204) {
        showNotification('Registro actualizado correctamente')
        onCancel()
      }
      if (result) setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      <Grid container className={classes.root} justify="center">
        <Typography variant="h5">{userToAddWorkspaces.email}</Typography>
      </Grid>
      <Grid container className={classes.root} justify="center" style={{ marginTop: '20px' }}>
        {workspaces.map((workspace) => {
          return (
            <React.Fragment key={workspace.id}>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    checked={model.indexOf(workspace.id) > -1}
                    onChange={(event) => handleChangeWorkspace(event.target)}
                    value={workspace.id}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={workspace.name}
              />
            </React.Fragment>
          )
        })}
      </Grid>
      <Grid container justify="center" style={{ marginTop: '20px' }}>
        <ButtonLoading type="submit" isLoading={isLoading} color="linkedin" label="Aceptar" />
        <div className={classes.wrapper}>
          <Button color="danger" disabled={isLoading} onClick={onCancel}>
            Cacelar
          </Button>
        </div>
      </Grid>
    </form>
  )
}

AddUserToWorkspace.propTypes = {
  userToAddWorkspaces: PropTypes.objectOf({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired
}

export default AddUserToWorkspace
