import React from 'react'

import TimeEntry from 'components/TimeEntry'
import TimeEntriesTable from 'components/TimeEntriesTable'

const TimeTrackerContainer = () => {
  return (
    <div>
      <TimeEntry />
      <TimeEntriesTable />
    </div>
  )
}
export default React.memo(TimeTrackerContainer)
