import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'

// Material ui core
import IconButton from '@material-ui/core/IconButton'

// Material ui icons
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
  icon: {
    fontSize: 20
  }
}))

const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const classes = styles()
  useEffect(() => {
    return () => {}
  }, [])

  const action = (key) => (
    <>
      <IconButton
        color="inherit"
        aria-label="cerrar toast"
        onClick={() => {
          closeSnackbar(key)
        }}
        size="small"
      >
        <CloseIcon className={classes.icon} />
      </IconButton>
    </>
  )

  const showNotistack = (message, variant = 'success') => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      action
    })
  }
  return showNotistack
}
export default useCustomSnackbar
