import React, { useContext, useState } from 'react'
import { EMPLOYEE } from 'constants/rolesConst'
import { addTimes } from 'utils/functions'
import reportContext from 'contextApi/ReportsContext'
import authContext from 'contextApi/AuthContext'

// Material ui core
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { IconButton } from '@material-ui/core'

// Material ui icons
import TimerIcon from '@material-ui/icons/Timer'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CreateIcon from '@material-ui/icons/Create'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import useStyles from './reports.styles'

const ReportList = () => {
  const { state, handleOpenModalTimeEntryForm, handleOpenModalDeleteTimeEntry } = useContext(
    reportContext
  )
  const {
    userLoggedin: { role }
  } = useContext(authContext)
  const classes = useStyles()
  const [activeWorkspace, setActiveWorkspace] = useState('')
  const [activeProject, setActiveProject] = useState('')

  const parseTimeSpan = (time) => {
    const split = time.split(':')
    const hours =
      split[0].indexOf('.') === -1
        ? split[0]
        : Number(split[0].split('.')[0]) * 24 + Number(split[0].split('.')[1])
    const minutes = split[1]
    const seconds = split[2].split('.')[0]
    return `${hours}:${minutes}:${seconds}`
  }
  const getTotalHour = () => {
    if (role === EMPLOYEE) {
      return addTimes(state.timeEntries.map((tiemEntry) => tiemEntry.totalTime))
    }
    return state.timeEntries.reduce((accomulator, current) => {
      return { totalHours: accomulator.totalHours + current.totalHours }
    }).totalHours
  }

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Typography variant="h5" align="center" style={{ margin: 10 }}>
        Total Horas &nbsp;
        {state.timeEntries.length === 0 ? 0 : getTotalHour()}
      </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Workspace</TableCell>
            <TableCell align="right">Tiempo total</TableCell>
            {role !== EMPLOYEE ? <TableCell align="right">Horas totales</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.timeEntries.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h5" align="center">
                  No hay registros
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            state.timeEntries.map((row) => (
              <React.Fragment key={row.workSpace}>
                <TableRow id={row.workspaceId}>
                  <TableCell component="th" scope="row">
                    {row.workspaceId === activeWorkspace ? (
                      <KeyboardArrowDownIcon
                        onClick={() => {
                          if (row.workspaceId === activeWorkspace) setActiveWorkspace('')
                          else setActiveWorkspace(row.workspaceId)
                        }}
                        fontSize="small"
                        className={classes.icon}
                      />
                    ) : (
                      <KeyboardArrowRightIcon
                        onClick={() => {
                          if (row.workspaceId === activeProject) setActiveWorkspace('')
                          else setActiveWorkspace(row.workspaceId)
                        }}
                        fontSize="small"
                        className={classes.icon}
                      />
                    )}
                    {row.workSpace}
                  </TableCell>
                  <TableCell align="right">{parseTimeSpan(row.totalTime)}</TableCell>
                  {role !== EMPLOYEE ? <TableCell align="right">{row.totalHours}</TableCell> : null}
                </TableRow>
                <TableRow
                  className={activeWorkspace === row.workspaceId ? classes.active : classes.hide}
                >
                  <TableCell component="th" scope="row" colSpan={5}>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Proyecto</TableCell>
                            <TableCell align="right">Tiempo total</TableCell>
                            {role !== EMPLOYEE ? (
                              <TableCell align="right">Horas totales</TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.projects.map((project) => (
                            <React.Fragment key={project.project}>
                              <TableRow id={project.projectId}>
                                <TableCell component="th" scope="row">
                                  {project.projectId === activeProject ? (
                                    <KeyboardArrowDownIcon
                                      onClick={() => {
                                        if (project.projectId === activeProject)
                                          setActiveProject('')
                                        else setActiveProject(project.projectId)
                                      }}
                                      fontSize="small"
                                      className={classes.icon}
                                    />
                                  ) : (
                                    <KeyboardArrowRightIcon
                                      onClick={() => {
                                        if (project.projectId === activeProject)
                                          setActiveProject('')
                                        else setActiveProject(project.projectId)
                                      }}
                                      fontSize="small"
                                      className={classes.icon}
                                    />
                                  )}

                                  {project.project}
                                </TableCell>
                                <TableCell align="right">
                                  {parseTimeSpan(project.totalTime)}
                                </TableCell>
                                {role !== EMPLOYEE ? (
                                  <TableCell align="right">{project.totalHours}</TableCell>
                                ) : null}
                              </TableRow>
                              <TableRow
                                className={
                                  activeProject === project.projectId
                                    ? classes.active
                                    : classes.hide
                                }
                              >
                                <TableCell component="th" scope="row" colSpan={3}>
                                  <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Tarea</TableCell>
                                          <TableCell align="right">Duración</TableCell>
                                          <TableCell align="right">Usuario</TableCell>
                                          <TableCell align="right">Inicio</TableCell>
                                          <TableCell align="right">Fin</TableCell>
                                          {role !== EMPLOYEE && (
                                            <TableCell align="right">Acciones</TableCell>
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {project.timeEntries.map((entry) => (
                                          <TableRow key={entry.id}>
                                            <TableCell component="th" scope="row">
                                              <TimerIcon className={classes.iconTask} />
                                              {entry.description}
                                            </TableCell>
                                            <TableCell align="right">
                                              {parseTimeSpan(entry.duration)}
                                            </TableCell>
                                            <TableCell align="right">{entry.user.email}</TableCell>
                                            <TableCell align="right">
                                              {entry.start.substring(0, 16)}
                                            </TableCell>
                                            <TableCell align="right">
                                              {entry.stop.substring(0, 16)}
                                            </TableCell>
                                            {role !== EMPLOYEE && (
                                              <TableCell align="right">
                                                {/* <IconButton aria-label="Ver">
                                                <VisibilityIcon color="primary" />
                                              </IconButton> */}
                                                <IconButton
                                                  aria-label="Editar"
                                                  onClick={() =>
                                                    handleOpenModalTimeEntryForm(entry.id)
                                                  }
                                                >
                                                  <CreateIcon color="primary" />
                                                </IconButton>
                                                <IconButton
                                                  aria-label="Eliminar"
                                                  onClick={() =>
                                                    handleOpenModalDeleteTimeEntry(entry.id)
                                                  }
                                                >
                                                  <DeleteForeverIcon color="primary" />
                                                </IconButton>
                                              </TableCell>
                                            )}
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ReportList.propTypes = {}

export default React.memo(ReportList)
